import { OpeningHoursPriority } from "../../contexts/OpeningHoursContext";

export enum WeekDaysBinary {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
}

export interface ApiOpeningHours {
  startTime?: string;
  endTime?: string;
  pauseStartTime?: string;
  pauseEndTime?: string;
}

export interface ApiOpeningHoursRegularAndSpecial {
  weekdays: ApiWeekDays[];
  specialDays: ApiSpecialDays[];
}

export interface ApiWeekDays {
  weekDay: number;
  startTime: string;
  endTime: string;
  pauseStartTime: string;
  pauseEndTime: string;
}

export interface ApiSpecialDays {
  id: number;
  priority: number;
  validFromDate?: string;
  validToDate?: string;
  startTime?: string;
  endTime?: string;
  pauseStartTime?: string;
  pauseEndTime?: string;
}

export type ApiUpdateOpeningHours = {
  startTime: string;
  endTime: string;
  pauseStartTime?: string;
  pauseEndTime?: string;

  priority: OpeningHoursPriority;
  weekDay?: WeekDaysBinary;

  id?: number;
  validFromDate: string;
  validToDate?: string;
  updatedAt: string;
};

export interface ApiUpdateOpeningHoursWithBranchAndEmployee {
  branchId: number;
  employeeId?: number;
  hasOwnRegularOpeningTimes?: boolean;
  openingTimes: ApiUpdateOpeningHours[];
}

export interface ApiDeleteSpecialDaysOpeningHours {
  branchId: number;
  employeeId?: number;
  openingTimesIds: number[];
}
