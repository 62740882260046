import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../../common/inputs/TextInput";
import { mailPattern } from "../../helpers/utils";
import useAxios from "axios-hooks";
import { urls } from "../../helpers/urls";
import { Employee } from "../../models/Employee";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { EmployeeViewModel } from "../../models/viewModels/Employee";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface IEmployeeForm {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface Props {
  employee?: Employee;
  onSave: () => void;
  onCancel: () => void;
}

const EmployeeForm: React.FC<Props> = ({ employee, onCancel, onSave }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { state: userSettings } = useContext(UserSettingsContext);
  const { register, handleSubmit, errors } = useForm<IEmployeeForm>({
    defaultValues: employee
      ? {
          email: employee.email,
          firstName: employee.firstName,
          lastName: employee.lastName,
          phoneNumber: employee.phoneNumber,
        }
      : undefined,
  });
  const [{ loading: isSavingEmployee }, saveEmployee] = useAxios(
    { url: urls.api.employees(employee?.id) },
    { manual: true },
  );

  const createEmployee = (formData: IEmployeeForm) => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when creating new employee.");
    }

    const requestBody = {
      ...formData,
      branchId: userSettings.branch.id,
    };

    return saveEmployee({ method: "POST", data: requestBody });
  };

  const updateEmployee = (formData: IEmployeeForm, employeeId: number) => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when creating new employee.");
    }

    const requestBody: EmployeeViewModel = {
      ...formData,
      branchId: userSettings.branch.id,
      hasOwnRegularOpeningTimes: employee?.hasOwnRegularOpeningTimes ?? false,
    };

    return saveEmployee({ method: "PUT", data: requestBody });
  };

  const onSubmit = async (data: IEmployeeForm) => {
    try {
      if (employee?.id) {
        await updateEmployee(data, employee.id);
      } else {
        await createEmployee(data);
      }

      enqueueSnackbar(t("common.savedSuccessfully"), { variant: "success" });
      onSave();
    } catch (e) {
      enqueueSnackbar(t("common.savingFailedTryAgain"), { variant: "error" });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="firstName"
          id="firstName"
          label={t("pages.employees.firstName")}
          error={errors.firstName?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="lastName"
          id="lastName"
          label={t("pages.employees.lastName")}
          error={errors.lastName?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
            pattern: {
              value: mailPattern,
              message: t("errors.pleaseEnterValidEmail"),
            },
          })}
          name="email"
          id="email"
          label={t("common.email")}
          error={errors.email?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="phoneNumber"
          id="phoneNumber"
          label={t("common.phone")}
          error={errors.phoneNumber?.message}
          isSolid={false}
        />
      </div>

      <div className="d-flex justify-content-start align-items-center">
        <LoadingButton
          color={"primary"}
          variant={"contained"}
          type="submit"
          className={"mr-2"}
          loading={isSavingEmployee}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant={"outlined"} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
      </div>
    </form>
  );
};

export default EmployeeForm;
