import { convertOpeningHoursFromApi } from "./openingHours";
import {
  ApiEmployeeOpeningHours,
  EmployeeOpeningHours,
} from "../../models/api/EmployeeOpeningHoursApi";

export const convertFromApi = (
  apiData: ApiEmployeeOpeningHours | undefined,
): EmployeeOpeningHours | undefined => {
  const openingHours = convertOpeningHoursFromApi(apiData);
  if (!openingHours) {
    return undefined;
  }

  return {
    ...openingHours,
    hasOwnRegularOpeningHours: apiData?.hasOwnRegularOpeningTimes ?? false,
  };
};
