import { DateTime } from "luxon";

export interface ITime {
  hour: number;
  minute: number;
}

const humanTimeFormat = "H:mm";
const wholeDayMinuteCount = 1440;

export class Time implements ITime {
  hour: number;
  minute: number;

  constructor(time: ITime) {
    this.hour = time.hour;
    this.minute = time.minute;

    if (this.hour < 0 || 23 < this.hour || this.minute < 0 || 59 < this.minute) {
      throw new Error(`'${this.toString()}' is not valid time.`);
    }
  }

  plus({ hours = 0, minutes = 0 }: { hours?: number; minutes?: number }): Time {
    const newValue = this.toNumber() + hours * 60 + minutes;

    return Time.fromNumber(newValue % wholeDayMinuteCount); // modulo whole day, if it is over midnight
  }

  valueOf(): number {
    return this.toNumber();
  }

  equals(other: Time): boolean {
    return other.hour === this.hour && other.minute === this.minute;
  }

  toString(): string {
    return `${this.hour.toString().padStart(2, "0")}:${this.minute.toString().padStart(2, "0")}`;
  }

  toFormat(format: string = humanTimeFormat) {
    if (format === humanTimeFormat) {
      return `${this.hour}:${this.minute.toString().padStart(2, "0")}`;
    }

    throw new Error("Unknown time format");
  }

  private toNumber(): number {
    return this.hour * 60 + this.minute;
  }

  private static fromNumber(value: number): Time {
    const hour = Math.floor(value / 60);
    const minute = value % 60;

    return new Time({ hour, minute });
  }
}

export const getTimeFromDateTime = (dateTime: DateTime): Time =>
  new Time({
    hour: dateTime.hour,
    minute: dateTime.minute,
  });

export const getDateTimeLocalFromTime = (time: Time): DateTime =>
  DateTime.local().set({
    hour: time.hour,
    minute: time.minute,
    second: 0,
    millisecond: 0,
  });

export const getDateTimeUtcFromTime = (time: Time): DateTime =>
  DateTime.utc().set({
    hour: time.hour,
    minute: time.minute,
    second: 0,
    millisecond: 0,
  });

export const setTimeToDateTime = (dateTime: DateTime, time: Time) =>
  dateTime.set({ hour: time.hour, minute: time.minute, second: 0, millisecond: 0 });
