import React, { ChangeEvent, FC, useEffect, useState } from "react";
import BranchLogo from "./BranchLogo";
import { Button, Stack, Typography } from "@mui/material";
import { Branch } from "../../models/Branch";
import { useTranslation } from "react-i18next";
import { base64FromArrayBuffer } from "../../utils/fileUtils";
import { useSnackbar } from "notistack";
import { useBranchLogoBase64 } from "../../hooks/api/useBranchLogoBase64";

interface Props {
  branch: Branch | undefined;
  onChange: (logoBase64: string | undefined) => void;
}

const BranchLogoUploader: FC<Props> = ({ branch, onChange }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { originalLogoBase64 } = useBranchLogoBase64(branch?.id);

  const [uploadedLogo, setUploadedLogo] = useState<File>();
  const [isOriginalLogoDeleted, setIsOriginalLogoDeleted] = useState<boolean>(false);

  const isDeleteButtonVisible = (originalLogoBase64 && !isOriginalLogoDeleted) || uploadedLogo;
  const isUploadButtonVisible =
    (originalLogoBase64 && isOriginalLogoDeleted && !uploadedLogo) ||
    (!originalLogoBase64 && !uploadedLogo);

  useEffect(() => {
    if (originalLogoBase64) {
      onChange(originalLogoBase64);
    }
  }, [originalLogoBase64, onChange]);

  const imageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const maxImageSize = 1; // in MB
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > maxImageSize * 1000000) {
      enqueueSnackbar(
        t("common.imageIsBiggerThanXPleaseSelectSmallerImage", { maxImageSize: maxImageSize }),
        { variant: "error" },
      );

      return;
    }

    setUploadedLogo(file);

    try {
      onChange(base64FromArrayBuffer(await file.arrayBuffer()));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("common.imageCouldNotBeUploadedPleaseTryOtherOne"));

      setUploadedLogo(undefined);
    }
  };

  const deleteLogo = () => {
    setIsOriginalLogoDeleted(true);
    setUploadedLogo(undefined);

    onChange(undefined);
  };

  return (
    <Stack direction={"column"}>
      <Typography variant={"body2"}>Logo</Typography>

      <Stack direction={"row"} alignItems={"center"} gap={1} sx={{ my: 1 }}>
        {branch && !isOriginalLogoDeleted && (
          <BranchLogo branchId={branch.id} branchName={branch.name} sx={{ mr: 2 }} />
        )}

        {isDeleteButtonVisible && (
          <Button variant={"outlined"} onClick={deleteLogo}>
            {t("pages.branches.deleteLogo")}
          </Button>
        )}

        {isUploadButtonVisible && (
          <label htmlFor="contained-button-file">
            <input
              accept="image/*"
              id="contained-button-file"
              hidden
              type="file"
              onChange={imageUpload}
            />
            <Button variant={"outlined"} component="span" sx={{ mt: 1 }}>
              {t("common.selectFile")}...
            </Button>
          </label>
        )}

        {uploadedLogo && <Typography variant={"body2"}>{uploadedLogo.name}</Typography>}
      </Stack>
    </Stack>
  );
};

export default BranchLogoUploader;
