import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../common/inputs/TextInput";
import { urls } from "../helpers/urls";
import { mailPattern } from "../helpers/utils";
import LoginLayout from "../components/layouts/LoginLayout";
import useAxios from "axios-hooks";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";

interface ForgotPasswordForm {
  email: string;
}

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm<ForgotPasswordForm>();

  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);
  const [requestedEmail, setRequestedEmail] = useState<string>("");

  const [{ loading, error }, sendResetPasswordRequest] = useAxios({}, { manual: true });

  const onSubmit = async (data: ForgotPasswordForm) => {
    setIsRequestSent(true);
    setRequestedEmail(data.email);
    await sendResetPasswordRequest({ url: urls.api.requestResetPasswordEmail(data.email) });
  };

  return (
    <LoginLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className="my-2">{t("pages.forgotPassword.passwordRecovery")}</h2>
              </Card.Header>

              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    {loading && (
                      <Box sx={{ display: "flex", width: 1, justifyContent: "center" }}>
                        <CircularProgress sx={{ my: 3 }} />
                      </Box>
                    )}

                    {isRequestSent && !loading && !error && (
                      <Alert severity={"success"} sx={{ mb: 3 }}>
                        <Typography>
                          {t("pages.forgotPassword.weSentYouAnEmailPleaseCheckYourInbox", {
                            email: requestedEmail,
                          })}
                        </Typography>
                      </Alert>
                    )}

                    {isRequestSent && !loading && error && (
                      <Alert severity={"error"} sx={{ mb: 3 }}>
                        <Typography>{t("common.somethingWentWrongPleaseTryAgainLater")}</Typography>
                      </Alert>
                    )}

                    {(!isRequestSent || error) && (
                      <>
                        <div className="text-muted text-sm mb-3">
                          {t(
                            "pages.forgotPassword.afterEnteringYourEmailWeWillSendYouEmailWithResetLink",
                          )}
                        </div>

                        <TextInput
                          ref={register({
                            required: t<string>("pages.forgotPassword.pleaseEnterYourEmail"),
                            pattern: {
                              value: mailPattern,
                              message: t("pages.register.pleaseEnterValidEmail"),
                            },
                          })}
                          name="email"
                          type="text"
                          id="email"
                          label={t("common.email")}
                          error={errors.email?.message}
                          disabled={loading}
                        />
                      </>
                    )}
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <Link to={urls.login} className="text-sm">
                      {t("pages.forgotPassword.backToLogin")}
                    </Link>

                    {(!isRequestSent || error) && (
                      <Button variant="dark" type="submit" disabled={loading}>
                        {t("pages.forgotPassword.resetPassword")}
                      </Button>
                    )}
                  </div>
                </form>
              </Card.Body>

              <Card.Footer className="text-center">
                <Link to={urls.registerForm} className="text-sm">
                  {t("pages.login.dontHaveAccountYetRegisterNow")}
                </Link>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ForgotPasswordPage;
