import React, { FC, MouseEvent, useState } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const UserMenu: FC = () => {
  const { t } = useTranslation();
  const { isUserLoggedIn, signOut } = useAuthContext();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElement(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      {isUserLoggedIn && (
        <>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 2 }}>
            <AccountCircle />
          </IconButton>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElement)}
            onClose={handleCloseUserMenu}
            PaperProps={{ sx: { p: 1 } }}
          >
            <MenuItem onClick={signOut}>
              <Typography textAlign="center">{t("components.nav.logout")}</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default UserMenu;
