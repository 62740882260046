import React, { FC, useContext, useEffect } from "react";
import cx from "classnames";
import {
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from "./ReservationFormStateProvider";
import styles from "./ReservationForm.module.scss";
import Step1 from "./form-steps/Step1";
import Step2 from "./form-steps/Step2";
import Step3 from "./form-steps/Step3";
import Step4 from "./form-steps/Step4";
import ReservationFormSummary from "./ReservationFormSummary";
import { Reservation } from "../../models/Reservation";
import { DateTime } from "luxon";

interface Props {
  newReservation?: { employeeId: number; startingAt: DateTime };
  existingReservation?: Reservation;
  isWithSummary?: boolean;
  onSave: () => void;
}

const ReservationForm: FC<Props> = (props) => {
  if (props.newReservation && props.existingReservation) {
    throw new Error("In reservation form must not be set both - new and existing reservation");
  }

  const {
    formState: { step },
    dispatch,
  } = useContext(ReservationFormStateContext);

  useEffect(() => {
    dispatch(setReservationFormStepAction(1));
    dispatch(
      setReservationFormDataAction({
        id: props.existingReservation?.id,
        date: props.existingReservation?.startingAt ?? props.newReservation?.startingAt,
        employeeId: props.existingReservation?.employee?.id ?? props.newReservation?.employeeId,
        reservationTypeId: props.existingReservation?.reservationType?.id,
        customer: {
          firstName: props.existingReservation?.customer?.firstName,
          lastName: props.existingReservation?.customer?.lastName,
          email: props.existingReservation?.customer?.email,
          phoneNumber: props.existingReservation?.customer?.phoneNumber,
        },
      }),
    );
  }, [props, dispatch]);

  return (
    <div className={cx(styles.wrapper, "container", "px-0")}>
      <div className={styles.content}>
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {step === 4 && <Step4 onSave={props.onSave} />}
      </div>

      {props.isWithSummary && (
        <div className={styles.summary}>
          <ReservationFormSummary />
        </div>
      )}
    </div>
  );
};

ReservationForm.defaultProps = {
  isWithSummary: true,
};

export default ReservationForm;
