import React, { FC, ReactElement, useCallback, useEffect, useState } from "react";
import TimePicker from "../../common/inputs/TimePicker/TimePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { OpeningHoursWithPause } from "../../contexts/OpeningHoursContext";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import styles from "./OpeningHoursSingle.module.scss";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { DateTime } from "luxon";
import { appTimeZone } from "../../helpers/DateTimeService";
import { Tooltip } from "@mui/material";

const defaultStartTime = DateTime.fromObject({ hour: 8 }, { zone: appTimeZone });
const defaultEndTime = DateTime.fromObject({ hour: 16 }, { zone: appTimeZone });
const defaultPauseStartTime = DateTime.fromObject({ hour: 11 }, { zone: appTimeZone });
const defaultPauseEndTime = DateTime.fromObject({ hour: 11, minute: 30 }, { zone: appTimeZone });

interface PartialProps {
  dayName?: string;
  dayInput?: ReactElement;

  listId: string;

  copyHours?: (hours: OpeningHoursWithPause) => void;
  copiedHours?: OpeningHoursWithPause;

  openLabel?: string;
  closedLabel?: string;
}

export interface OpeningHoursChangeHandlers {
  onStartTimeChange: (date: DateTime | undefined) => void;
  onEndTimeChange: (date: DateTime | undefined) => void;
  onPauseStartTimeChange: (date: DateTime | undefined) => void;
  onPauseEndTimeChange: (date: DateTime | undefined) => void;
}

type Props = PartialProps & OpeningHoursWithPause & OpeningHoursChangeHandlers;

const OpeningHoursSingle: FC<Props> = (props) => {
  const { t } = useTranslation();

  const isOpenByProps = useCallback(() => {
    return !!(props.startTime || props.endTime || props.pauseStartTime || props.pauseEndTime);
  }, [props.startTime, props.endTime, props.pauseStartTime, props.pauseEndTime]);

  const [isOpen, setIsOpen] = useState<boolean>(isOpenByProps());
  useEffect(() => {
    setIsOpen(isOpenByProps());
  }, [isOpenByProps]);

  const isWithPauseByProps = useCallback(() => {
    return !!props.pauseStartTime || !!props.pauseEndTime;
  }, [props.pauseStartTime, props.pauseEndTime]);

  const [isWithPause, setIsWithPause] = useState<boolean>(isWithPauseByProps());
  useEffect(() => {
    setIsWithPause(isWithPauseByProps());
  }, [isWithPauseByProps]);

  const isWithCopyPaste = !!props.copyHours;

  const getCurrentOpeningHours = (): OpeningHoursWithPause => {
    return {
      startTime: props.startTime,
      endTime: props.endTime,
      pauseStartTime: props.pauseStartTime,
      pauseEndTime: props.pauseEndTime,
    };
  };

  const setCopiedOpeningHours = () => {
    if (!props.copiedHours) {
      return;
    }

    props.onStartTimeChange(props.copiedHours.startTime);
    props.onEndTimeChange(props.copiedHours.endTime);
    props.onPauseStartTimeChange(props.copiedHours.pauseStartTime);
    props.onPauseEndTimeChange(props.copiedHours.pauseEndTime);

    if (props.copiedHours.pauseStartTime || props.copiedHours.pauseEndTime) {
      setIsWithPause(true);
    }
  };

  const toggleIsOpen = () => {
    setIsOpen((_) => {
      if (_) {
        props.onStartTimeChange(undefined);
        props.onEndTimeChange(undefined);
        props.onPauseStartTimeChange(undefined);
        props.onPauseEndTimeChange(undefined);
      } else {
        props.onStartTimeChange(defaultStartTime);
        props.onEndTimeChange(defaultEndTime);
      }

      return !_;
    });
  };

  const toggleIsAdvanced = () => {
    setIsWithPause((_) => {
      if (_) {
        props.onPauseStartTimeChange(undefined);
        props.onPauseEndTimeChange(undefined);
      } else {
        props.onPauseStartTimeChange(defaultPauseStartTime);
        props.onPauseEndTimeChange(defaultPauseEndTime);
      }

      return !_;
    });
  };

  return (
    <>
      <tr className={cx(styles.openingHours, "show-child-on-hover")}>
        {(props.dayName || props.dayInput) && (
          <td className={"pr-1 align-middle text-nowrap"}>
            {props.dayName}
            {props.dayInput}
          </td>
        )}

        <td className={"align-middle pr-3"}>
          <Button
            size={"sm"}
            onClick={toggleIsOpen}
            className={cx("d-flex justify-content-around w-100 text-gray-700", {
              "btn-success-soft": isOpen,
              "btn-danger-soft": !isOpen,
            })}
          >
            <FontAwesomeIcon icon={isOpen ? faCheck : faTimes} className={"mr-1"} />
            {isOpen
              ? props.openLabel ?? t("components.openingHours.open")
              : props.closedLabel ?? t("components.openingHours.closed")}
          </Button>
        </td>

        {!isOpen && (
          <>
            <td colSpan={2} className={"text-center align-middle"}>
              <hr className={"solid w-25"} />
            </td>
          </>
        )}

        {isOpen && (
          <>
            <td className={"text-center align-middle"}>
              <div className={"d-flex align-items-center"}>
                {isWithPause ? (
                  <Tooltip title={t("components.openingHours.workTime") as string}>
                    <FontAwesomeIcon icon={faTools} className={cx("mr-2", styles.timesHelpIcon)} />
                  </Tooltip>
                ) : (
                  <div className={cx(styles.timesHelpIcon, "mr-2")} />
                )}
                <TimePicker
                  onChange={(_) => props.onStartTimeChange(_)}
                  value={props.startTime}
                  doNotChangeTimeZones={true}
                />
              </div>
              {isWithPause && (
                <div className={cx("d-flex align-items-center", styles.pauseTimes)}>
                  <Tooltip title={t("components.openingHours.pause") as string}>
                    <FontAwesomeIcon
                      icon={faUtensils}
                      className={cx("mt-1 mr-2", styles.timesHelpIcon)}
                    />
                  </Tooltip>
                  <TimePicker
                    onChange={(_) => props.onPauseStartTimeChange(_)}
                    value={props.pauseStartTime}
                    wrapperClassName={"mt-1"}
                    doNotChangeTimeZones={true}
                  />
                </div>
              )}
            </td>
            <td className={"text-center align-middle"}>
              <TimePicker
                onChange={(_) => props.onEndTimeChange(_)}
                value={props.endTime}
                doNotChangeTimeZones={true}
              />
              {isWithPause && (
                <TimePicker
                  onChange={(_) => props.onPauseEndTimeChange(_)}
                  value={props.pauseEndTime}
                  wrapperClassName={cx("mt-1", styles.pauseTimes)}
                  doNotChangeTimeZones={true}
                />
              )}
            </td>
          </>
        )}

        <td className={"align-middle"}>
          {isOpen && (
            <Tooltip
              title={
                isWithPause
                  ? "Odstrániť prestávku"
                  : (t("components.openingHours.addPause") as string)
              }
            >
              <Button
                size={"sm"}
                className="show-on-parent-hover btn-transparent-dark"
                onClick={() => toggleIsAdvanced()}
              >
                <FontAwesomeIcon icon={isWithPause ? "minus" : "plus"} className={"m-0 p-0"} />
              </Button>
            </Tooltip>
          )}
        </td>

        {isWithCopyPaste && (
          <>
            <td className={"align-middle"}>
              {isOpen && props.startTime && props.endTime && (
                <Tooltip title={t("common.copy") as string}>
                  <Button
                    size={"sm"}
                    className="show-on-parent-hover btn-transparent-dark"
                    onClick={() =>
                      !!props.copyHours ? props.copyHours(getCurrentOpeningHours()) : {}
                    }
                  >
                    <FontAwesomeIcon icon={"copy"} className={"m-0 p-0"} />
                  </Button>
                </Tooltip>
              )}
            </td>
            <td className={"align-middle"}>
              {props.copiedHours && (
                <Tooltip title={t("common.paste") as string}>
                  <Button
                    size={"sm"}
                    className="btn-transparent-dark show-on-parent-hover"
                    onClick={() => setCopiedOpeningHours()}
                  >
                    <FontAwesomeIcon icon={"paste"} className={"m-0 p-0"} />
                  </Button>
                </Tooltip>
              )}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export default OpeningHoursSingle;
