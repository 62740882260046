/* Action constants */
import {
  emptyWeekdays,
  OpeningHoursSpecialDay,
  OpeningHoursState,
  OpeningHoursWeekDay,
  OpeningHoursWithPause,
  WeekDays,
  WeekDaysObject,
} from "./OpeningHoursContext";
import { DateTime } from "luxon";

const getEmptyOpeningHours = (day: WeekDays): OpeningHoursWeekDay => {
  return { weekDay: day };
};

export const SET_WEEKDAYS = "opening-hours/set-weekdays";
export const SET_WEEKDAY_START_TIME = "opening-hours/set-weekday-start-time";
export const SET_WEEKDAY_END_TIME = "opening-hours/set-weekday-end-time";
export const SET_WEEKDAY_PAUSE_START_TIME = "opening-hours/set-weekday-pause-start-time";
export const SET_WEEKDAY_PAUSE_END_TIME = "opening-hours/set-weekday-pause-end-time";

export const SET_EXISTING_SPECIAL_DAYS = "opening-hours/set-existing-special-days";
export const ADD_NEW_SPECIAL_DAY = "opening-hours/add-new-special-day";
export const REMOVE_SPECIAL_DAY = "opening-hours/remove-special-day";
export const CHANGE_SPECIAL_DAY = "opening-hours/change-special-day";

/* Action interfaces */
export interface SetWeekdaysAction {
  type: typeof SET_WEEKDAYS;
  value: WeekDaysObject | undefined;
}

export interface SetWeekdayStartTimeAction {
  type: typeof SET_WEEKDAY_START_TIME;
  value: { day: WeekDays; time?: DateTime };
}

export interface SetWeekdayEndTimeAction {
  type: typeof SET_WEEKDAY_END_TIME;
  value: { day: WeekDays; time?: DateTime };
}

export interface SetWeekdayPauseStartTimeAction {
  type: typeof SET_WEEKDAY_PAUSE_START_TIME;
  value: { day: WeekDays; time?: DateTime };
}

export interface SetWeekdayPauseEndTimeAction {
  type: typeof SET_WEEKDAY_PAUSE_END_TIME;
  value: { day: WeekDays; time?: DateTime };
}

export interface SetExistingSpecialDaysAction {
  type: typeof SET_EXISTING_SPECIAL_DAYS;
  value: (OpeningHoursSpecialDay & { id: number })[];
}

export interface AddNewSpecialDayAction {
  type: typeof ADD_NEW_SPECIAL_DAY;
  value: OpeningHoursSpecialDay;
}

export interface RemoveSpecialDayAction {
  type: typeof REMOVE_SPECIAL_DAY;
  value: { idType: "id" | "index"; id: number };
}

export interface ChangeSpecialDayAction {
  type: typeof CHANGE_SPECIAL_DAY;
  value: {
    idType: "id" | "index";
    id: number;
    whatToChange: keyof Omit<OpeningHoursSpecialDay, "priority">;
    newValue: DateTime | undefined;
  };
}

/* Action functions */
const setWeekdays = (
  value: WeekDaysObject | undefined,
  state: OpeningHoursState,
): OpeningHoursState => {
  return {
    ...state,
    weekdays: value ?? emptyWeekdays,
  };
};

const setWeekdaySpecificTime = (
  value: { day: WeekDays; time?: DateTime },
  state: OpeningHoursState,
  specificTime: keyof OpeningHoursWithPause,
): OpeningHoursState => {
  if (value.time) {
    value.time = value.time.set({ second: 0 });
  }

  return {
    ...state,
    weekdays: {
      ...state.weekdays,
      [value.day]: {
        ...(state.weekdays[value.day] ?? getEmptyOpeningHours(value.day)),
        [specificTime]: value.time,
      },
    },
  };
};

const setExistingSpecialDays = (
  value: (OpeningHoursSpecialDay & { id: number })[],
  state: OpeningHoursState,
): OpeningHoursState => {
  return {
    ...state,
    existingSpecialDays: value,
    newSpecialDays: [],
  };
};
const addNewSpecialDay = (
  value: OpeningHoursSpecialDay,
  state: OpeningHoursState,
): OpeningHoursState => {
  return {
    ...state,
    newSpecialDays: state.newSpecialDays.concat(value),
  };
};
const removeSpecialDay = (
  value: { idType: "id" | "index"; id: number },
  state: OpeningHoursState,
): OpeningHoursState => {
  if (value.idType === "index") {
    let newArray = [...state.newSpecialDays];
    newArray.splice(value.id, 1);

    return { ...state, newSpecialDays: newArray };
  }

  let newArray = [...state.existingSpecialDays];
  let itemIndex = newArray.findIndex((_) => _.id === value.id);
  if (itemIndex === -1) {
    throw new Error(`No special opening hours found by ID ${value.id}`);
  }
  newArray.splice(itemIndex, 1);

  return { ...state, existingSpecialDays: newArray };
};
const changeSpecialDay = (
  value: {
    idType: "id" | "index";
    id: number;
    whatToChange: keyof Omit<OpeningHoursSpecialDay, "priority">;
    newValue: DateTime | undefined;
  },
  state: OpeningHoursState,
): OpeningHoursState => {
  if (value.whatToChange)
    if (value.idType === "index") {
      let newArray = [...state.newSpecialDays];
      newArray[value.id][value.whatToChange] = value.newValue;

      return { ...state, newSpecialDays: newArray };
    }

  let newArray = [...state.existingSpecialDays];
  let itemIndex = newArray.findIndex((_) => _.id === value.id);
  if (itemIndex === -1) {
    throw new Error(`No special opening hours found by ID ${value.id}`);
  }
  newArray[itemIndex][value.whatToChange] = value.newValue;

  return { ...state, existingSpecialDays: newArray };
};

export type OpeningHoursActionType =
  | SetWeekdaysAction
  | SetWeekdayStartTimeAction
  | SetWeekdayEndTimeAction
  | SetWeekdayPauseStartTimeAction
  | SetWeekdayPauseEndTimeAction
  | SetExistingSpecialDaysAction
  | AddNewSpecialDayAction
  | RemoveSpecialDayAction
  | ChangeSpecialDayAction;

export const OpeningHoursReducer = (
  state: OpeningHoursState,
  action: OpeningHoursActionType,
): OpeningHoursState => {
  switch (action.type) {
    case SET_WEEKDAYS:
      return setWeekdays(action.value, state);
    case SET_WEEKDAY_START_TIME:
      return setWeekdaySpecificTime(action.value, state, "startTime");
    case SET_WEEKDAY_END_TIME:
      return setWeekdaySpecificTime(action.value, state, "endTime");
    case SET_WEEKDAY_PAUSE_START_TIME:
      return setWeekdaySpecificTime(action.value, state, "pauseStartTime");
    case SET_WEEKDAY_PAUSE_END_TIME:
      return setWeekdaySpecificTime(action.value, state, "pauseEndTime");
    case SET_EXISTING_SPECIAL_DAYS:
      return setExistingSpecialDays(action.value, state);
    case ADD_NEW_SPECIAL_DAY:
      return addNewSpecialDay(action.value, state);
    case REMOVE_SPECIAL_DAY:
      return removeSpecialDay(action.value, state);
    case CHANGE_SPECIAL_DAY:
      return changeSpecialDay(action.value, state);
    default:
      return state;
  }
};
