import { EmployeesReservationsCalendar } from "../EmployeesReservationsCalendar";
import {
  convertFromApi as convertEmployeeReservationsFromApi,
  EmployeeReservationsApi,
} from "./EmployeeReservationsApi";
import { parseDateToLocal, parseToUtc } from "../../helpers/DateTimeService";
import { ApiOpeningHours } from "./OpeningHoursApi";
import { parseApiOpeningHours } from "../../utils/apiConverters/openingHours";

export interface EmployeesReservationsCalendarApi {
  date: string;
  employeesReservations: EmployeeReservationsApi[];
  branchOpeningTime: ApiOpeningHours;
}

export const convertFromApi = (
  apiData: EmployeesReservationsCalendarApi,
): EmployeesReservationsCalendar => {
  const shouldParseBranchOpeningTime =
    apiData.branchOpeningTime &&
    apiData.branchOpeningTime.startTime &&
    apiData.branchOpeningTime.endTime;

  const dayLocal = parseDateToLocal(apiData.date);
  const dayUTC = parseToUtc(apiData.date);

  return {
    date: dayUTC,
    employeesReservations: apiData.employeesReservations.map((_) =>
      convertEmployeeReservationsFromApi(_, dayUTC),
    ),
    branchOpeningTime: shouldParseBranchOpeningTime
      ? parseApiOpeningHours(apiData.branchOpeningTime, dayLocal)
      : undefined,
  };
};
