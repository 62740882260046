import React, { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "../../common/inputs/DatePicker";
import cx from "classnames";
import OpeningHoursSingle from "./OpeningHoursSingle";
import { OpeningHoursSpecialDay } from "../../contexts/OpeningHoursContext";
import { DateTime } from "luxon";
import { toJSDate } from "../../helpers/DateTimeService";
import { Tooltip } from "@mui/material";

interface Props {
  listId: string;

  onDateFromChange: (date: DateTime | undefined) => void;
  onDateToChange: (date: DateTime | undefined) => void;

  onStartTimeChange: (date: DateTime | undefined) => void;
  onEndTimeChange: (date: DateTime | undefined) => void;
  onPauseStartTimeChange: (date: DateTime | undefined) => void;
  onPauseEndTimeChange: (date: DateTime | undefined) => void;

  onRemove: () => void;

  openLabel?: string;
  closedLabel?: string;
}

const ExceptionalOpeningHoursSingle: FC<Props & OpeningHoursSpecialDay> = (props) => {
  const [dayMode, setDayMode] = useState<"single-day" | "day-range">(
    !props.validToDate ? "single-day" : "day-range",
  );

  const toggleDayMode = () => {
    setDayMode((_) => {
      if (_ === "single-day") {
        const defaultValidToDate = props.validFromDate
          ? props.validFromDate.plus({ days: 1 })
          : DateTime.local().set({ hour: 0, minute: 0, second: 0 });

        props.onDateToChange(defaultValidToDate);

        return "day-range";
      } else {
        props.onDateToChange(undefined);

        return "single-day";
      }
    });
  };

  return (
    <OpeningHoursSingle
      listId={props.listId + "-exception"}
      startTime={props.startTime}
      endTime={props.endTime}
      pauseStartTime={props.pauseStartTime}
      pauseEndTime={props.pauseEndTime}
      onStartTimeChange={props.onStartTimeChange}
      onEndTimeChange={props.onEndTimeChange}
      onPauseStartTimeChange={props.onPauseStartTimeChange}
      onPauseEndTimeChange={props.onPauseEndTimeChange}
      openLabel={props.openLabel}
      closedLabel={props.closedLabel}
      dayInput={
        <div className={"d-flex align-items-center flex-row"}>
          <Tooltip title={"Odstrániť"}>
            <Button
              variant={"danger"}
              size={"sm"}
              onClick={() => props.onRemove()}
              className={"show-on-parent-hover mr-2"}
            >
              <FontAwesomeIcon icon={"minus"} className={"m-0 p-0"} />
            </Button>
          </Tooltip>

          <div className={"d-flex flex-column mr-1"}>
            <div className={"d-flex align-items-center"}>
              <label
                className={cx("d-flex badge mr-1 mb-0", { invisible: dayMode === "single-day" })}
              >
                OD
              </label>
              <DatePicker
                onChange={(_) => props.onDateFromChange(_ ?? undefined)}
                value={props.validFromDate}
                wrapperClassName={cx({ "mb-1": dayMode === "day-range" })}
                maxDate={
                  dayMode === "day-range"
                    ? props.validToDate
                      ? toJSDate(props.validToDate.minus({ days: 1 }))
                      : undefined
                    : undefined
                }
              />
            </div>

            {dayMode === "day-range" && (
              <div className={"d-flex align-items-center"}>
                <label className={"d-flex badge mr-1 mb-0"}>DO</label>
                <DatePicker
                  onChange={(_) => props.onDateToChange(_ ?? undefined)}
                  value={props.validToDate}
                  minDate={
                    props.validFromDate
                      ? toJSDate(props.validFromDate.plus({ days: 1 }))
                      : undefined
                  }
                />
              </div>
            )}
          </div>

          <Tooltip
            title={dayMode === "single-day" ? "Zadať rozsah dátumov" : "Zadať konkrétny dátum"}
          >
            <Button
              size={"sm"}
              onClick={() => toggleDayMode()}
              className={"m-0 btn-transparent-dark show-on-parent-hover"}
            >
              <FontAwesomeIcon
                icon={dayMode === "single-day" ? "calendar-plus" : "calendar-minus"}
                className={"m-0 p-0"}
              />
            </Button>
          </Tooltip>
        </div>
      }
    />
  );
};

export default ExceptionalOpeningHoursSingle;
