import React, { FC, MouseEvent, RefObject, useState } from "react";
import { utcToLocal } from "../../helpers/DateTimeService";
import { ReservationCalendarBlock } from "./CalendarEmployeeReservations";
import { Reservation } from "../../models/Reservation";
import cx from "classnames";

import styles from "./CalendarEmployeeReservation.module.scss";
import useInterval from "../../hooks/useInterval";

interface Props {
  reservation: ReservationCalendarBlock;
  editReservation: (reservation: Reservation) => void;
  deleteReservation: (reservationId: number) => void;
  tableWrapper: RefObject<HTMLDivElement>;
}

const timeFormat = "H:mm";
const defaultSecondsUntilConfirmDeletion = 3;

const CalendarEmployeeReservation: FC<Props> = (props) => {
  const [detailsOffset, setDetailsOffset] = useState<number>(0);
  const [isCountingDown, setIsCountingDown] = useState<boolean>(false);
  const [secondsUntilConfirmDeletion, setSecondsUntilConfirmDeletion] = useState<number>(
    defaultSecondsUntilConfirmDeletion,
  );
  const [canDelete, setCanDelete] = useState<boolean>(false);

  useInterval(
    () => {
      setSecondsUntilConfirmDeletion((_) => {
        if (_ === 1) {
          setIsCountingDown(false);
          setCanDelete(true);
        }

        return _ - 1;
      });
    },
    isCountingDown ? 1000 : undefined,
  );

  const onHover = () => {
    setDetailsOffset(props.tableWrapper.current?.scrollLeft ?? 0);
  };

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onHover();
  };

  const prepareDeleteConfirmation = () => {
    setIsCountingDown(true);
  };

  const reset = () => {
    setSecondsUntilConfirmDeletion(defaultSecondsUntilConfirmDeletion);
    setCanDelete(false);
    setIsCountingDown(false);
  };

  const basicInfo = (
    <>
      <b>{props.reservation.reservation?.reservationType?.name}</b>
      <br />
      {utcToLocal(props.reservation.startingAt).toFormat(timeFormat)} -{" "}
      {utcToLocal(props.reservation.endingAt).toFormat(timeFormat)}
      <br />
      {props.reservation.reservation?.customer?.firstName}{" "}
      {props.reservation.reservation?.customer?.lastName}
    </>
  );

  return (
    <div
      className={cx(
        styles.leftShadow,
        "badge badge-primary-soft text-left overflow-hidden show-child-on-hover p-0",
      )}
      style={{
        width: `${props.reservation.displayRemWidth}rem`,
      }}
      onMouseEnter={onHover}
      onMouseLeave={reset}
      onClick={onClick}
    >
      <div
        style={{
          marginLeft: `-${detailsOffset}px`,
          minWidth: `${props.reservation.displayRemWidth}rem`,
        }}
        className={cx(
          styles.reservationBlockFull,
          "badge badge-primary-soft p-1 text-left show-on-parent-hover",
        )}
      >
        <div className="mb-2">{basicInfo}</div>
        <div>
          <button
            type="button"
            className="btn btn-primary btn-xs mr-1"
            onClick={() =>
              props.reservation.reservation
                ? props.editReservation(props.reservation.reservation)
                : null
            }
          >
            Upraviť
          </button>

          {!isCountingDown && !canDelete ? (
            <button
              type="button"
              className="btn btn-outline-danger btn-xs"
              onClick={prepareDeleteConfirmation}
            >
              Zmazať
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-danger btn-xs"
              onClick={() =>
                props.reservation.reservation
                  ? props.deleteReservation(props.reservation.reservation.id)
                  : null
              }
              disabled={!canDelete}
            >
              {canDelete ? "Naozaj zmazať" : `Počkajte (${secondsUntilConfirmDeletion})`}
            </button>
          )}
        </div>
      </div>

      <div className={"p-1"}>{basicInfo}</div>
    </div>
  );
};

export default CalendarEmployeeReservation;
