import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { urls } from "../helpers/urls";

const PrivateRoute: FC<RouteProps> = (props) => {
  const { isUserLoggedIn } = useAuthContext();

  return isUserLoggedIn ? <Route {...props}>{props.children}</Route> : <Redirect to={urls.login} />;
};

export default PrivateRoute;
