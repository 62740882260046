import { OpeningHoursSpecialDay } from "../../contexts/OpeningHoursContext";
import {
  toApiDateString,
  toApiDateTimeString,
  toApiTimeString,
} from "../../helpers/DateTimeService";
import { convertOpeningHoursFromApi, defaultEmptyTime } from "./openingHours";
import { DateTime } from "luxon";
import { BranchOpeningHours } from "../../components/opening-hours/branch/BranchOpeningHoursForm";
import { ApiBranchOpeningHours } from "../../models/api/BranchOpeningHoursApi";
import { ApiUpdateOpeningHours } from "../../models/api/OpeningHoursApi";

export const convertSpecialDaysToApiModel = (
  specialDay: OpeningHoursSpecialDay & { id?: number },
): ApiUpdateOpeningHours => {
  if (
    !specialDay.validFromDate ||
    (specialDay.startTime && !specialDay.endTime) ||
    (!specialDay.startTime && specialDay.endTime) ||
    (specialDay.pauseStartTime && !specialDay.pauseEndTime) ||
    (!specialDay.pauseStartTime && specialDay.pauseEndTime)
  ) {
    console.error(specialDay);
    throw new Error("Inconsistent special day opening hours or valid from / to.");
  }

  return {
    id: specialDay?.id,
    startTime: specialDay.startTime ? toApiTimeString(specialDay.startTime) : defaultEmptyTime,
    endTime: specialDay.endTime ? toApiTimeString(specialDay.endTime) : defaultEmptyTime,
    pauseStartTime: specialDay.pauseStartTime
      ? toApiTimeString(specialDay.pauseStartTime)
      : defaultEmptyTime,
    pauseEndTime: specialDay.pauseEndTime
      ? toApiTimeString(specialDay.pauseEndTime)
      : defaultEmptyTime,

    priority: specialDay.priority,

    validFromDate: toApiDateString(specialDay.validFromDate),
    validToDate: toApiDateString(specialDay.validToDate ?? specialDay.validFromDate),
    updatedAt: toApiDateTimeString(DateTime.utc()),
  };
};

export const convertFromApi = (
  apiData: ApiBranchOpeningHours | undefined,
): BranchOpeningHours | undefined => convertOpeningHoursFromApi(apiData);
