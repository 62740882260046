import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Calendar from "../components/calendar/Calendar";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { unwrap } from "../helpers/utils";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AdminLayout from "../components/layouts/AdminLayout";
import OverlayContainer from "../common/OverlayContainer/OverlayContainer";
import ReservationForm from "../components/reservations/ReservationForm";
import { Reservation } from "../models/Reservation";
import { Employee } from "../models/Employee";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import CalendarNavigation from "../components/calendar/CalendarNavigation";
import { DateTime } from "luxon";
import { appTimeZone } from "../helpers/DateTimeService";
import { getTimeFromDateTime, Time } from "../utils/timeUtils";
import { useSnackbar } from "notistack";
import EmptyCalendarHints from "../components/calendar/EmptyCalendarHints";
import { useCalendarApi } from "../hooks/api/useCalendarApi";

const CalendarPage: FC = () => {
  const { t } = useTranslation();
  const { state: userSettings } = useContext(UserSettingsContext);
  const branch = unwrap(userSettings.branch);

  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<"none" | "new" | "existing">("none");
  const [formEditingReservation, setFormEditingReservation] = useState<Reservation>();

  const onFormClose = () => {
    setForm("none");
    setFormNewReservation(undefined);
    setFormEditingReservation(undefined);
  };

  const getDefaultSettings = () => {
    let showDays = 7;
    let startDate = DateTime.utc();
    let endDate = startDate.plus({ days: showDays - 1 });

    return {
      startDate: startDate,
      endDate: endDate,
      minutesStep: 30,
      hourRemWidth: 13,
    };
  };
  const [settings, setSettings] = useState(getDefaultSettings());
  const [filteredEmployee, setFilteredEmployee] = useState<Employee>();

  const [{ data: employees, loading: isLoadingEmployees }, reloadEmployees] = useAxios<Employee[]>(
    {},
    { manual: true },
  );

  const filteredEmployees = useMemo(() => {
    if (!filteredEmployee) {
      return employees;
    }

    return employees.filter((_) => _.id === filteredEmployee.id);
  }, [employees, filteredEmployee]);

  const {
    data: employeeReservationsByDate,
    loading: isLoadingCalendar,
    reload: reloadCalendar,
  } = useCalendarApi(branch.id, settings.startDate, settings.endDate, filteredEmployee?.id);

  useEffect(() => {
    if (!userSettings.branch) {
      return;
    }

    reloadEmployees({ url: urls.api.employeesOfBranch(userSettings.branch.id) });
    reloadCalendar();
  }, [reloadCalendar, reloadEmployees, userSettings.branch]);

  useEffect(() => {
    reloadCalendar();
  }, [settings.startDate, settings.endDate, reloadCalendar]);

  const [
    { loading: isDeletingReservation, error: deleteReservationError },
    runDeleteReservation,
  ] = useAxios({ method: "DELETE" }, { manual: true });
  const [deleteReservationButtonClicked, setDeleteReservationButtonClicked] = useState<boolean>(
    false,
  );

  const deleteReservation = (id: number): void => {
    setDeleteReservationButtonClicked(true);
    runDeleteReservation({ url: urls.api.reservations(id) });
  };

  const afterDeleted = useCallback(() => {
    reloadCalendar();
    enqueueSnackbar(t("common.deletedSuccessfully"), { variant: "success" });
    setDeleteReservationButtonClicked(false);
  }, [enqueueSnackbar, reloadCalendar, t]);

  const afterDeleteFailed = useCallback(() => {
    enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
    setDeleteReservationButtonClicked(false);
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    if (!isDeletingReservation && deleteReservationButtonClicked && !deleteReservationError) {
      afterDeleted();
    }
  }, [isDeletingReservation, deleteReservationButtonClicked, deleteReservationError, afterDeleted]);

  useEffect(() => {
    if (deleteReservationError) {
      afterDeleteFailed();
    }
  }, [deleteReservationError, afterDeleteFailed]);

  const isLoading = isLoadingEmployees || isLoadingCalendar;

  const [formNewReservation, setFormNewReservation] = useState<{
    employeeId: number;
    startingAt: DateTime;
  }>();

  const changeWeek = (to: "previous" | "next") => {
    const addDaysCount = to === "previous" ? -7 : 7;

    setSettings((_) => {
      return {
        ..._,
        startDate: _.startDate.plus({ days: addDaysCount }),
        endDate: _.endDate.plus({ days: addDaysCount }),
      };
    });
  };

  const timeline = useMemo<
    | {
        timelineStartTime: Time;
        timelineEndTime: Time;
      }
    | undefined
  >(() => {
    let startTime: Time | undefined;
    let endTime: Time | undefined;

    if (!employeeReservationsByDate) {
      return undefined;
    }

    const toLocalTime = (dateTime: DateTime) => getTimeFromDateTime(dateTime.setZone(appTimeZone));

    for (let i = 0; i < employeeReservationsByDate.length; i++) {
      const currentDay = employeeReservationsByDate[i];
      const currentBranchOpeningTime = employeeReservationsByDate[i].branchOpeningTime;

      if (!startTime) {
        startTime = currentBranchOpeningTime?.startTime
          ? toLocalTime(currentBranchOpeningTime?.startTime)
          : undefined;
      } else if (
        startTime &&
        currentBranchOpeningTime?.startTime &&
        toLocalTime(currentBranchOpeningTime.startTime) < startTime
      ) {
        startTime = toLocalTime(currentBranchOpeningTime.startTime);
      }

      if (!endTime) {
        endTime = currentBranchOpeningTime?.endTime
          ? toLocalTime(currentBranchOpeningTime?.endTime)
          : undefined;
      } else if (
        endTime &&
        currentBranchOpeningTime?.endTime &&
        endTime < toLocalTime(currentBranchOpeningTime.endTime)
      ) {
        endTime = toLocalTime(currentBranchOpeningTime.endTime);
      }

      for (let j = 0; j < currentDay.employeesReservations?.length; j++) {
        const employeeOpeningHours = currentDay.employeesReservations[j]?.openingTime;
        if (!employeeOpeningHours) {
          continue;
        }

        if (!startTime) {
          startTime = employeeOpeningHours?.startTime
            ? toLocalTime(employeeOpeningHours?.startTime)
            : undefined;
        } else if (
          startTime &&
          employeeOpeningHours?.startTime &&
          toLocalTime(employeeOpeningHours.startTime) < startTime
        ) {
          startTime = toLocalTime(employeeOpeningHours.startTime);
        }

        if (!endTime) {
          endTime = employeeOpeningHours?.endTime
            ? toLocalTime(employeeOpeningHours?.endTime)
            : undefined;
        } else if (
          endTime &&
          employeeOpeningHours?.endTime &&
          endTime < toLocalTime(employeeOpeningHours.endTime)
        ) {
          endTime = toLocalTime(employeeOpeningHours.endTime);
        }
      }
    }

    if (!startTime || !endTime) {
      return undefined;
    }

    return {
      timelineStartTime: startTime,
      timelineEndTime: endTime,
    };
  }, [employeeReservationsByDate]);

  return (
    <AdminLayout
      title={t("components.nav.calendar")}
      toolbar={
        <CalendarNavigation
          showPreviousWeek={() => changeWeek("previous")}
          showNextWeek={() => changeWeek("next")}
          filterEmployee={(_) => setFilteredEmployee(_)}
        />
      }
    >
      {isLoading && !employeeReservationsByDate ? (
        <div className="text-center">
          <Spinner animation="border" variant={"dark"} role="status" className="m-5">
            <span className="sr-only">{t("common.loading")}...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {!timeline && <EmptyCalendarHints />}

          {timeline && (
            <Calendar
              {...settings}
              {...timeline}
              addReservation={(employeeId, startDateTime) => {
                setForm("new");
                setFormNewReservation({ employeeId, startingAt: startDateTime });
              }}
              editReservation={(_) => {
                setForm("existing");
                setFormEditingReservation(_);
              }}
              deleteReservation={(_) => deleteReservation(_)}
              employeeReservationsByDate={employeeReservationsByDate}
              employees={filteredEmployees}
              isReloading={isLoading}
            />
          )}

          {form !== "none" && (
            <OverlayContainer
              title={
                form === "new"
                  ? t("pages.calendar.addReservation")
                  : t("pages.calendar.editReservation")
              }
              onClose={onFormClose}
            >
              <ReservationForm
                newReservation={formNewReservation}
                existingReservation={formEditingReservation}
                isWithSummary={false}
                onSave={() => {
                  setForm("none");
                  setFormNewReservation(undefined);
                  setFormEditingReservation(undefined);
                  reloadCalendar();
                }}
              />
            </OverlayContainer>
          )}
        </>
      )}
    </AdminLayout>
  );
};

export default CalendarPage;
