import { convertFromApi as convertReservationFromApi, ReservationApi } from "./ReservationApi";
import { EmployeeReservations } from "../EmployeeReservations";
import { Employee } from "../Employee";
import { DateTime } from "luxon";
import { parseApiOpeningHours } from "../../utils/apiConverters/openingHours";
import { ApiOpeningHours } from "./OpeningHoursApi";

export interface EmployeeReservationsApi {
  employee: Employee;
  reservations: ReservationApi[];
  openingTime: ApiOpeningHours;
  showOwnOpeningTimes: boolean;
}

export const convertFromApi = (
  apiData: EmployeeReservationsApi,
  day: DateTime,
): EmployeeReservations => {
  const shouldParseOpeningTime =
    apiData.openingTime && apiData.openingTime.startTime && apiData.openingTime.endTime;

  return {
    employee: apiData.employee,
    reservations: apiData.reservations.map((_) => convertReservationFromApi(_)),
    openingTime: shouldParseOpeningTime
      ? parseApiOpeningHours(apiData.openingTime, day)
      : undefined,
    showOwnOpeningTimes: apiData.showOwnOpeningTimes,
  };
};
