import React, { createRef, FC, useCallback, useEffect, useState } from "react";
import CalendarRow from "./CalendarRow";
import { eachDayOfInterval, isSameDay } from "../../helpers/DateTimeService";
import { Card } from "react-bootstrap";
import { EmployeeReservations } from "../../models/EmployeeReservations";
import { useTranslation } from "react-i18next";
import { EmployeesReservationsCalendar } from "../../models/EmployeesReservationsCalendar";
import { Reservation } from "../../models/Reservation";
import { Employee } from "../../models/Employee";
import { DateTime } from "luxon";
import cx from "classnames";

import styles from "./Calendar.module.scss";
import { OpeningHoursWithPause } from "../../contexts/OpeningHoursContext";
import { Time } from "../../utils/timeUtils";

interface Props {
  startDate: DateTime;
  endDate: DateTime;
  timelineStartTime: Time;
  timelineEndTime: Time;

  employeeReservationsByDate: EmployeesReservationsCalendar[];
  employees: Employee[];

  addReservation: (employeeId: number, startDateTime: DateTime) => void;
  editReservation: (reservation: Reservation) => void;
  deleteReservation: (reservationId: number) => void;

  minutesStep: number;
  hourRemWidth: number;
  isReloading: boolean;
}

const Calendar: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [calendarDates, setCalendarDates] = useState<DateTime[]>([]);

  const initializeCalendarDays = useCallback(() => {
    setCalendarDates(eachDayOfInterval(props.startDate, props.endDate));
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    initializeCalendarDays();
  }, [initializeCalendarDays]);

  const getCalendarHeaderTimes = (): Time[] => {
    let allTimes: Time[] = [];
    for (
      let currentTime = props.timelineStartTime;
      currentTime < props.timelineEndTime;
      currentTime = currentTime.plus({ minutes: props.minutesStep })
    ) {
      allTimes.push(currentTime);
    }

    return allTimes;
  };

  const getHeaderBlockRemWidth = (): number => {
    return props.hourRemWidth * (props.minutesStep / 60);
  };

  const getEmployeesReservationsByDate = (
    date: DateTime,
  ): {
    employeesReservations: EmployeeReservations[];
    branchOpeningHours: OpeningHoursWithPause | undefined;
  } => {
    const employeeReservationsByDate = props.employeeReservationsByDate?.find((_) =>
      isSameDay(date, _.date),
    );

    return {
      employeesReservations: employeeReservationsByDate?.employeesReservations || [],
      branchOpeningHours: employeeReservationsByDate?.branchOpeningTime,
    };
  };

  const tableWrapperRef = createRef<HTMLDivElement>();

  return (
    <>
      <Card className={cx({ [styles.isLoading]: props.isReloading })}>
        <Card.Body>
          <div className="table-responsive" ref={tableWrapperRef}>
            <table className="table">
              <thead>
                <tr>
                  <th>{t("pages.calendar.day")}</th>
                  <th>{t("common.employee")}</th>
                  <th>
                    <div className="d-flex flex-row">
                      {getCalendarHeaderTimes().map((_) => (
                        <div style={{ width: `${getHeaderBlockRemWidth()}rem` }} key={_.toString()}>
                          {_.toFormat("H:mm")}
                        </div>
                      ))}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {calendarDates.map((_) => (
                  <CalendarRow
                    {...getEmployeesReservationsByDate(_)}
                    date={_}
                    timelineStartTime={props.timelineStartTime}
                    timelineEndTime={props.timelineEndTime}
                    allEmployees={props.employees}
                    hourRemWidth={props.hourRemWidth}
                    addReservation={props.addReservation}
                    editReservation={props.editReservation}
                    deleteReservation={props.deleteReservation}
                    tableWrapper={tableWrapperRef}
                    key={_.toString()}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Calendar;
