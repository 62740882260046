import React, { FC, useContext, useEffect, useState } from "react";
import StandardOpeningHours from "../StandardOpeningHours";
import ExceptionalOpeningHours from "../ExceptionalOpeningHours";
import useAxios from "axios-hooks";
import { urls } from "../../../helpers/urls";
import Preloader from "../../common/Preloader";
import { ApiBranchOpeningHours } from "../../../models/api/BranchOpeningHoursApi";
import { parseJson } from "../../../helpers/utils";
import { OpeningHoursContext, OpeningHoursState } from "../../../contexts/OpeningHoursContext";
import { SET_EXISTING_SPECIAL_DAYS, SET_WEEKDAYS } from "../../../contexts/OpeningHoursReducer";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { getEndDayOfMonth, getStartDayOfMonth, nowLocal } from "../../../helpers/DateTimeService";
import MonthNavigation from "../MonthNavigation";
import { convertFromApi } from "../../../utils/apiConverters/branchOpeningHours";
import {
  convertToCreateApiModel,
  convertToUpdateApiModel,
} from "../../../utils/apiConverters/openingHours";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export type BranchOpeningHours = OpeningHoursState & {};

interface Props {
  branchId: number;
  onSave: () => void;
  onCancel: () => void;
}

const BranchOpeningHoursForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { state: openingHoursState, dispatch: openingHoursDispatch } = useContext(
    OpeningHoursContext,
  );

  const [fromDate, setFromDate] = useState<DateTime>(
    getStartDayOfMonth(nowLocal().year, nowLocal().month),
  );
  const [toDate, setToDate] = useState<DateTime>(
    getEndDayOfMonth(nowLocal().year, nowLocal().month),
  );

  const [url, setUrl] = useState<string>(
    urls.api.openingHoursOfBranch(props.branchId, fromDate, toDate),
  );
  useEffect(() => {
    setUrl(urls.api.openingHoursOfBranch(props.branchId, fromDate, toDate));
  }, [props.branchId, fromDate, toDate]);

  const [{ data: openingHours, loading: isLoadingOpeningHours }, reloadOpeningHours] = useAxios<
    BranchOpeningHours | undefined
  >({
    url: url,
    transformResponse: (response) => {
      return convertFromApi(parseJson<ApiBranchOpeningHours>(response));
    },
  });

  const [{ loading: isSavingNewSpecialDays }, saveNewSpecialDays] = useAxios(
    {
      url: urls.api.openingTimes,
      method: "POST",
    },
    { manual: true },
  );
  const [{ loading: isUpdatingOpeningHours }, updateOpeningHours] = useAxios(
    {
      url: urls.api.openingTimes,
      method: "PUT",
    },
    { manual: true },
  );

  useEffect(() => {
    openingHoursDispatch({ type: SET_WEEKDAYS, value: openingHours?.weekdays });
    openingHoursDispatch({
      type: SET_EXISTING_SPECIAL_DAYS,
      value: openingHours?.existingSpecialDays ?? [],
    });
  }, [openingHours, openingHoursDispatch]);

  useEffect(() => {
    reloadOpeningHours({ url: url });
  }, [props.branchId, fromDate, toDate, reloadOpeningHours, url]);

  const save = async () => {
    try {
      await updateOpeningHours({
        data: convertToUpdateApiModel(props.branchId, undefined, undefined, openingHoursState),
      });

      enqueueSnackbar(t("components.openingHours.standardOpeningHoursSavedSuccessfully"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("components.openingHours.standardOpeningHoursSavingFailed"), {
        variant: "error",
      });
    }

    try {
      await saveNewSpecialDays({
        data: convertToCreateApiModel(props.branchId, undefined, openingHoursState.newSpecialDays),
      });

      enqueueSnackbar(t("components.openingHours.exceptionalOpeningHoursSavedSuccessfully"), {
        variant: "success",
      });

      props.onSave();
    } catch {
      enqueueSnackbar(t("components.openingHours.exceptionalOpeningHoursSavingFailed"), {
        variant: "error",
      });
    }
  };

  const selectPreviousMonth = () => {
    const nextMonth = fromDate.minus({ months: 1 });

    setFromDate(getStartDayOfMonth(nextMonth.year, nextMonth.month));
    setToDate(getEndDayOfMonth(nextMonth.year, nextMonth.month));
  };

  const selectNextMonth = () => {
    const nextMonth = fromDate.plus({ months: 1 });

    setFromDate(getStartDayOfMonth(nextMonth.year, nextMonth.month));
    setToDate(getEndDayOfMonth(nextMonth.year, nextMonth.month));
  };

  return (
    <>
      <h4>Štandardné otváracie hodiny</h4>
      {isLoadingOpeningHours ? (
        <Preloader />
      ) : openingHours ? (
        <StandardOpeningHours />
      ) : (
        "Nepodarilo sa načítať otváracie hodiny"
      )}

      <hr className={"my-3"} />

      <div className={"d-flex align-items-center justify-content-between"}>
        <h4>Výnimočné otváracie hodiny</h4>
        <MonthNavigation
          dayInCurrentMonth={fromDate}
          selectPreviousMonth={selectPreviousMonth}
          selectNextMonth={selectNextMonth}
        />
      </div>

      {isLoadingOpeningHours ? (
        <Preloader />
      ) : openingHours ? (
        <ExceptionalOpeningHours relatedTo={"branch"} />
      ) : (
        "Nepodarilo sa načítať otváracie hodiny"
      )}

      <div className="d-flex justify-content-start align-items-center mt-5">
        <LoadingButton
          color={"primary"}
          variant={"contained"}
          onClick={save}
          className={"mr-2"}
          loading={isUpdatingOpeningHours || isSavingNewSpecialDays}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant={"outlined"} onClick={props.onCancel}>
          {t("common.cancel")}
        </Button>
      </div>
    </>
  );
};

export default BranchOpeningHoursForm;
