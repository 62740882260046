import React, { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { LoginResponseDto } from "../models/api/LoginResponseDto";
import { User } from "../models/User";
import { setAccessTokenToAxios } from "../axios";
import { cookieKeys } from "../cookies";
import { ACCESS_TOKEN_EXPIRATION_TIME } from "../utils/constants";
import { useUserApi } from "../hooks/api/useUserApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface IAuthContext {
  signIn: (email: string, password: string, rememberMe: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  isVerifyingUser: boolean;
  isUserLoggedIn: boolean;
  user?: User;
}

export const AuthContext = createContext<IAuthContext>({
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  isVerifyingUser: false,
  isUserLoggedIn: false,
});

const AuthStateProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { user, isLoading: isLoadingUser, fetch: fetchUser } = useUserApi();

  const [{ loading: isLoggingIn }, login] = useAxios<LoginResponseDto>(
    {
      url: urls.api.login,
      method: "POST",
    },
    { manual: true },
  );

  const [currentUser, setCurrentUser] = useState<User>();

  const [, register] = useAxios(
    {
      url: urls.api.register,
      method: "POST",
    },
    { manual: true },
  );

  const [cookies, setCookie, removeCookie] = useCookies([cookieKeys.accessToken]);

  /**
   * Load access token from cookies and fetch current from API.
   */
  const loadAccessTokenAndCurrentUser = useCallback(async () => {
    const storedAccessToken = cookies[cookieKeys.accessToken];

    if (!storedAccessToken) {
      setCurrentUser(undefined);
      setAccessTokenToAxios(undefined);

      return;
    }

    setAccessTokenToAxios(storedAccessToken);

    try {
      await fetchUser();
    } catch {
      enqueueSnackbar(t("pages.login.yourLogInSessionExpiredPleaseLogIn"), { variant: "error" });
      removeCookie(cookieKeys.accessToken);
    }
  }, [cookies, enqueueSnackbar, fetchUser, removeCookie, t]);

  useEffect(() => {
    setCurrentUser(user);
  }, [user]);

  useEffect(() => {
    loadAccessTokenAndCurrentUser();
  }, [cookies, loadAccessTokenAndCurrentUser]);

  const storeAccessTokenToCookies = (token: string, rememberMe: boolean) => {
    const date = new Date();
    date.setTime(date.getTime() + ACCESS_TOKEN_EXPIRATION_TIME);
    setCookie(cookieKeys.accessToken, token, {
      path: "/",
      ...(rememberMe ? { expires: date } : {}),
    });
  };

  const signIn = (email: string, password: string, rememberMe: boolean) => {
    return login({ data: { email, password } }).then(({ data }) => {
      storeAccessTokenToCookies(data.token, rememberMe);
    });
  };

  const signOut = () =>
    // TODO: call logout API
    Promise.resolve().then(() => {
      removeCookie(cookieKeys.accessToken);

      setAccessTokenToAxios(undefined);
      setCurrentUser(undefined);
    });

  const signUp = (email: string, password: string) =>
    register({
      data: {
        email,
        password,
      },
    }).then(() => {});

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        signUp,
        isUserLoggedIn: !!currentUser,
        isVerifyingUser: isLoadingUser || isLoggingIn,
        user: currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthStateProvider, useAuthContext };
