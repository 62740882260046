import React, { FC } from "react";
import { Alert, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { salesEmail } from "../../helpers/utils";

const RegistrationInfo: FC = () => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  return (
    <Alert severity={"info"} sx={{ mb: spacing(2) }}>
      {t(
        "pages.register.registerIsForNowAllowedToSomeUserIfYouWantToHaveAnAccountContactUsToEmail",
        { email: salesEmail },
      )}
    </Alert>
  );
};

export default RegistrationInfo;
