import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarBlockType } from "./CalendarEmployeeReservations";
import { DateTime } from "luxon";
import { Tooltip } from "@mui/material";
import { utcToLocal } from "../../helpers/DateTimeService";

interface Props {
  displayRemWidth: number;
  addReservation: () => void;
  type: CalendarBlockType.EmptyOpen | CalendarBlockType.EmptyClosed | CalendarBlockType.Pause;
  startingAt: DateTime;
}

const CalendarRowFiller: FC<Props> = (props) => {
  if (props.type !== CalendarBlockType.EmptyOpen) {
    return (
      <div
        className={"d-flex align-items-center bg-light rounded overflow-hidden"}
        style={{ width: `${props.displayRemWidth}rem`, minHeight: "42px" }}
      >
        <span className={"text-uppercase small ml-3 text-muted"}>
          {props.type !== CalendarBlockType.Pause ? "Zatvorené" : "Prestávka"}
        </span>
      </div>
    );
  }

  return (
    <div
      className={"d-flex align-items-center show-child-on-hover"}
      style={{ width: `${props.displayRemWidth}rem` }}
    >
      {props.displayRemWidth < 3 ? null : (
        <Tooltip title={utcToLocal(props.startingAt).toFormat("d.M. (ccc), HH:mm")}>
          <button
            type="button"
            className={"btn btn-transparent-dark btn-icon show-on-parent-hover"}
            onClick={props.addReservation}
          >
            <FontAwesomeIcon icon={"plus"} className={"m-0 p-0"} />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default CalendarRowFiller;
