import React, { FC } from "react";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../contexts/AuthContext";
import { unwrap } from "../helpers/utils";
import { Branch } from "../models/Branch";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { Button, Card } from "react-bootstrap";
import welcomeSvg from "../img/svgs/welcome.svg";
import branchSvg from "../img/svgs/branch.svg";
import serviceSvg from "../img/svgs/services.svg";
import employeeSvg from "../img/svgs/employee.svg";
import calendarSvg from "../img/svgs/calendar.svg";
import { useHistory } from "react-router-dom";

const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user: userFromContext } = useAuthContext();
  const user = unwrap(userFromContext);

  const [{ data: branches }] = useAxios<Branch[]>(
    urls.api.branchesOfCompany(user.adminOfCompanyId),
  );

  const hasUserSetFirstBranch = !(branches?.length === 1 && !branches[0].name);

  const redirectToCalendar = () => {
    history.push(urls.calendar);
  };

  const redirectToBranches = () => {
    history.push(urls.companyBranches);
  };

  const redirectToReservationTypes = () => {
    history.push(urls.companyReservationTypes);
  };

  const redirectToEmployees = () => {
    history.push(urls.companyEmployees);
  };

  return (
    <AdminLayout title={t("components.nav.dashboard")}>
      {!hasUserSetFirstBranch && (
        <Card>
          <div className={"row"}>
            <div className={"col-md-6 px-5 py-5"}>
              <h1 className={"display-4 mb-4"}>{t("pages.dashboard.welcomeInBookinator")}</h1>
              <p className={"mb-4"}>
                {t("pages.dashboard.letsSetUpEverythingYouNeedFirstAddDetailsAboutYourFirstBranch")}
              </p>
              <Button variant={"dark"} onClick={redirectToBranches}>
                {t("pages.dashboard.setupFirstBranch")}
              </Button>
            </div>

            <div className={"col-md-6 pb-5 d-flex justify-content-center"}>
              <img
                src={welcomeSvg}
                alt={t("pages.dashboard.welcomeInBookinator")}
                className={"mt-n5 px-5 w-100"}
              />
            </div>
          </div>
        </Card>
      )}

      {hasUserSetFirstBranch && (
        <div className={"row"}>
          <div className={"col-md-6 mt-5 mb-5"}>
            <Card className={"h-100 px-4 pb-4 d-flex flex-column justify-content-between"}>
              <div className={"text-center w-100"}>
                <img
                  src={calendarSvg}
                  alt={t("pages.calendar.calendar")}
                  className={"mt-n5 mb-5 px-5 w-75"}
                />
                <h1 className={"mb-4"}>{t("pages.calendar.calendar")}</h1>
                <p className={"mb-4"}>{t("pages.calendar.description")}</p>
              </div>
              <Button variant={"dark"} onClick={() => redirectToCalendar()}>
                {t("pages.calendar.showCalendar")}
              </Button>
            </Card>
          </div>

          <div className={"col-md-6 mt-5 mb-5"}>
            <Card className={"h-100 px-4 pb-4 d-flex flex-column justify-content-between"}>
              <div className={"text-center w-100"}>
                <img
                  src={branchSvg}
                  alt={t("pages.branches.branches")}
                  className={"mt-n5 mb-5 px-5 w-75"}
                />
                <h1 className={"mb-4"}>{t("pages.branches.branches")}</h1>
                <p className={"mb-4"}>{t("pages.branches.description")}</p>
              </div>
              <Button variant={"dark"} onClick={() => redirectToBranches()}>
                {t("pages.branches.manageBranches")}
              </Button>
            </Card>
          </div>

          <div className={"col-md-6 mt-5 mb-5"}>
            <Card className={"h-100 px-4 pb-4 d-flex flex-column justify-content-between"}>
              <div className={"text-center w-100"}>
                <img
                  src={serviceSvg}
                  alt={t("pages.reservationTypes.reservationTypes")}
                  className={"mt-n5 mb-5 px-5 w-75"}
                />
                <h1 className={"mb-4"}>{t("pages.reservationTypes.reservationTypes")}</h1>
                <p className={"mb-4"}>{t("pages.reservationTypes.description")}</p>
              </div>
              <Button variant={"dark"} onClick={() => redirectToReservationTypes()}>
                {t("pages.reservationTypes.manageReservationTypes")}
              </Button>
            </Card>
          </div>

          <div className={"col-md-6 mt-5 mb-5"}>
            <Card className={"h-100 px-4 pb-4 d-flex flex-column justify-content-between"}>
              <div className={"text-center w-100"}>
                <img
                  src={employeeSvg}
                  alt={t("pages.employees.employees")}
                  className={"mt-n5 mb-5 px-5 w-75"}
                />
                <h1 className={"mb-4"}>{t("pages.employees.employees")}</h1>
                <p className={"mb-4"}>{t("pages.employees.description")}</p>
              </div>
              <Button variant={"dark"} onClick={() => redirectToEmployees()}>
                {t("pages.employees.manageEmployees")}
              </Button>
            </Card>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default DashboardPage;
