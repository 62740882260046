import sk from "./i18n/common/sk.json";
import en from "./i18n/common/en.json";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(LanguageDetector).init({
  fallbackLng: "sk",
  ns: ["translations"],
  defaultNS: "translations",
  lng: "sk",
  resources: {
    sk,
    en,
  },
  interpolation: { escapeValue: false }, // React already does escaping
  detection: {
    order: ["htmlTag"],
  },
});

export default i18next;
