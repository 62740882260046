import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseStep from "./BaseStep";
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from "../ReservationFormStateProvider";
import styles from "./Step3.module.scss";
import { DateTime } from "luxon";
import cx from "classnames";
import DateTimePicker from "../../../common/inputs/DateTimePicker";

const Step3: React.FC = () => {
  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext);

  const { t } = useTranslation();

  const { handleSubmit, control, errors } = useForm<ReservationFormData>({
    defaultValues: data,
  });

  const onSubmit = (formData: { date: DateTime; time: DateTime }) => {
    dispatch(setReservationFormDataAction({ ...data, ...formData }));
    dispatch(setReservationFormStepAction(4));
  };

  const getBaseStepError = () => {
    // @ts-ignore
    return errors.date?.message;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t("components.reservationForm.selectDateAndTime")}
        error={getBaseStepError()}
      >
        <div className={cx(styles.wrapper, "container")}>
          <div className={"row"}>
            <Controller
              defaultValue={data.date ?? DateTime.utc()}
              render={({ onChange, value }) => <DateTimePicker onChange={onChange} value={value} />}
              name="date"
              rules={{
                required: t("errors.required") as string,
              }}
              control={control}
            />
          </div>
        </div>
      </BaseStep>
    </form>
  );
};

export default Step3;
