import React, { FC } from "react";
import { DateTime } from "luxon";
import TimePicker from "./TimePicker/TimePicker";
import DatePicker from "./DatePicker";

interface Props {
  value: DateTime | undefined;
  onChange: (value: DateTime) => void;
  wrapperClassName?: string;
}

const DateTimePicker: FC<Props> = (props) => {
  const onChange = (value: DateTime) => {
    props.onChange(value);
  };

  return (
    <>
      <DatePicker onChange={onChange} value={props.value} wrapperClassName={"col-md-6 mb-2"} />
      <TimePicker onChange={onChange} value={props.value} wrapperClassName={"col-md-6 mb-2"} />
    </>
  );
};

export default DateTimePicker;
