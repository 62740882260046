import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../../common/inputs/TextInput";
import useAxios from "axios-hooks";
import { urls } from "../../helpers/urls";
import { ReservationType } from "../../models/ReservationType";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface IReservationTypeForm {
  name: string;
  priceEur: number;
  minutesDuration: number;
}

interface Props {
  reservationType?: ReservationType;
  onSave: () => void;
  onCancel: () => void;
}

const DURATION_MIN_VALUE = 1;
const PRICE_MIN_VALUE = 0;

const ReservationTypeForm: React.FC<Props> = ({ reservationType, onSave, onCancel }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state: userSettings } = useContext(UserSettingsContext);

  const { register, handleSubmit, errors } = useForm<IReservationTypeForm>({
    defaultValues: reservationType
      ? {
          name: reservationType.name,
          priceEur: reservationType.priceEur,
          minutesDuration: reservationType.minutesDuration,
        }
      : undefined,
  });
  const [{ loading: isSavingReservationType }, saveReservationType] = useAxios(
    { url: urls.api.reservationTypes(reservationType?.id) },
    { manual: true },
  );

  const createReservationType = (formData: IReservationTypeForm) => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when creating reservation type.");
    }

    const requestBody = {
      ...formData,
      branchId: userSettings.branch.id,
    };

    return saveReservationType({ method: "POST", data: requestBody });
  };

  const updateReservationType = (formData: IReservationTypeForm, reservationTypeId: number) => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when creating reservation type.");
    }

    let requestBody = {
      ...formData,
      branchId: userSettings.branch.id,
      id: reservationTypeId,
    };

    return saveReservationType({ method: "PUT", data: requestBody });
  };

  const onSubmit = async (data: IReservationTypeForm) => {
    try {
      if (reservationType?.id) {
        await updateReservationType(data, reservationType.id);
      } else {
        await createReservationType(data);
      }

      enqueueSnackbar(t("common.savedSuccessfully"), { variant: "success" });
      onSave();
    } catch (e) {
      enqueueSnackbar(t("common.savingFailedTryAgain"), { variant: "error" });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="name"
          id="name"
          label={t("common.name")}
          error={errors.name?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
            min: {
              value: PRICE_MIN_VALUE,
              message: t("errors.minimalValueIsX", { minimalValue: PRICE_MIN_VALUE }),
            },
          })}
          name="priceEur"
          id="priceEur"
          label={t("pages.reservationTypes.price")}
          type="number"
          error={errors.priceEur?.message}
          isSolid={false}
          endAdornment={"€"}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
            min: {
              value: DURATION_MIN_VALUE,
              message: t("errors.minimalValueIsX", { minimalValue: DURATION_MIN_VALUE }),
            },
          })}
          name="minutesDuration"
          id="minutesDuration"
          label={t("pages.reservationTypes.duration")}
          type="number"
          error={errors.minutesDuration?.message}
          isSolid={false}
          endAdornment={t("common.minutesShortcut")}
        />
      </div>

      <div className="d-flex justify-content-start align-items-center">
        <LoadingButton
          color={"primary"}
          variant={"contained"}
          type="submit"
          className={"mr-2"}
          loading={isSavingReservationType}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant={"outlined"} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
      </div>
    </form>
  );
};

export default ReservationTypeForm;
