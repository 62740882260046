import { DateTime } from "luxon";
import {
  localToUTC,
  parseTimeToLocal,
  parseToUtc,
  setDateWithoutChangingTime,
  toApiDateString,
  toApiDateTimeString,
  toApiTimeString,
} from "../../helpers/DateTimeService";
import {
  ApiBranchOpeningHours,
  ApiCreateSpecialDaysOpeningHours,
} from "../../models/api/BranchOpeningHoursApi";
import {
  allWeekdays,
  emptyWeekdays,
  OpeningHoursPriority,
  OpeningHoursSpecialDay,
  OpeningHoursState,
  OpeningHoursWeekDay,
  OpeningHoursWithPause,
  WeekDays,
  WeekDaysObject,
} from "../../contexts/OpeningHoursContext";
import { binaryToWeekDay, weekDayToBinary } from "../../helpers/utils";
import { ApiEmployeeOpeningHours } from "../../models/api/EmployeeOpeningHoursApi";
import {
  ApiOpeningHours,
  ApiSpecialDays,
  ApiUpdateOpeningHours,
  ApiUpdateOpeningHoursWithBranchAndEmployee,
  ApiWeekDays,
  WeekDaysBinary,
} from "../../models/api/OpeningHoursApi";
import { convertSpecialDaysToApiModel } from "./branchOpeningHours";

const setDateIfNotUndefined = (dateTime: DateTime, dateToBeSet?: DateTime): DateTime => {
  if (!dateToBeSet) {
    return dateTime;
  }

  return setDateWithoutChangingTime(dateTime, dateToBeSet);
};

export const parseApiOpeningHours = (
  apiData: ApiOpeningHours,
  useDate?: DateTime,
): OpeningHoursWithPause => {
  const defaultPauseValue = "00:00:00";
  const hasPause =
    apiData.pauseStartTime &&
    apiData.pauseEndTime &&
    !(apiData.pauseStartTime === defaultPauseValue && apiData.pauseEndTime === defaultPauseValue);

  return {
    startTime: apiData.startTime
      ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.startTime), useDate))
      : undefined,
    endTime: apiData.endTime
      ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.endTime), useDate))
      : undefined,
    pauseStartTime:
      apiData.pauseStartTime && hasPause
        ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.pauseStartTime), useDate))
        : undefined,
    pauseEndTime:
      apiData.pauseEndTime && hasPause
        ? localToUTC(setDateIfNotUndefined(parseTimeToLocal(apiData.pauseEndTime), useDate))
        : undefined,
  };
};

export const defaultEmptyTime = "00:00:00";
const convertWeekDay = (apiData: ApiWeekDays): OpeningHoursWeekDay => {
  const isClosed =
    (!apiData.startTime && !apiData.endTime) ||
    (apiData.startTime === defaultEmptyTime && apiData.endTime === defaultEmptyTime);
  const isNoPause =
    (!apiData.pauseStartTime && !apiData.pauseEndTime) ||
    (apiData.pauseStartTime === defaultEmptyTime && apiData.pauseEndTime === defaultEmptyTime);

  return {
    weekDay: binaryToWeekDay(apiData.weekDay),
    startTime: apiData.startTime && !isClosed ? parseTimeToLocal(apiData.startTime) : undefined,
    endTime: apiData.endTime && !isClosed ? parseTimeToLocal(apiData.endTime) : undefined,
    pauseStartTime:
      apiData.pauseStartTime && !isNoPause ? parseTimeToLocal(apiData.pauseStartTime) : undefined,
    pauseEndTime:
      apiData.pauseEndTime && !isNoPause ? parseTimeToLocal(apiData.pauseEndTime) : undefined,
  };
};

const convertSpecialDay = (apiData: ApiSpecialDays): OpeningHoursSpecialDay & { id: number } => {
  const isClosed =
    (!apiData.startTime && !apiData.endTime) ||
    (apiData.startTime === defaultEmptyTime && apiData.endTime === defaultEmptyTime);
  const isNoPause =
    (!apiData.pauseStartTime && !apiData.pauseEndTime) ||
    (apiData.pauseStartTime === defaultEmptyTime && apiData.pauseEndTime === defaultEmptyTime);

  const validFromDate = apiData.validFromDate ? parseToUtc(apiData.validFromDate) : undefined;
  let validToDate = apiData.validToDate ? parseToUtc(apiData.validToDate) : undefined;
  if (validFromDate && validToDate) {
    if (validToDate.diff(validFromDate, "days").days < 1) {
      validToDate = undefined;
    }
  }

  return {
    id: apiData.id,
    priority: apiData.priority,
    validFromDate: validFromDate,
    validToDate: validToDate,
    startTime: apiData.startTime && !isClosed ? parseTimeToLocal(apiData.startTime) : undefined,
    endTime: apiData.endTime && !isClosed ? parseTimeToLocal(apiData.endTime) : undefined,
    pauseStartTime:
      apiData.pauseStartTime && !isNoPause ? parseTimeToLocal(apiData.pauseStartTime) : undefined,
    pauseEndTime:
      apiData.pauseEndTime && !isNoPause ? parseTimeToLocal(apiData.pauseEndTime) : undefined,
  };
};

export const convertOpeningHoursFromApi = (
  apiData: ApiBranchOpeningHours | ApiEmployeeOpeningHours | undefined,
): OpeningHoursState | undefined => {
  if (!apiData) {
    return undefined;
  }

  const weekdaysFromApi = (apiWeekDays: ApiWeekDays[]): WeekDaysObject => {
    const weekdayFromApi = (day: WeekDays): OpeningHoursWeekDay | undefined => {
      const apiWeekday = apiWeekDays.filter((_) => (weekDayToBinary(day) & _.weekDay) > 0);
      if (apiWeekday.length === 0) {
        return undefined;
      }

      return convertWeekDay(apiWeekday[0]);
    };

    return {
      [WeekDays.Monday]: weekdayFromApi(WeekDays.Monday),
      [WeekDays.Tuesday]: weekdayFromApi(WeekDays.Tuesday),
      [WeekDays.Wednesday]: weekdayFromApi(WeekDays.Wednesday),
      [WeekDays.Thursday]: weekdayFromApi(WeekDays.Thursday),
      [WeekDays.Friday]: weekdayFromApi(WeekDays.Friday),
      [WeekDays.Saturday]: weekdayFromApi(WeekDays.Saturday),
      [WeekDays.Sunday]: weekdayFromApi(WeekDays.Sunday),
    };
  };

  return {
    weekdays: apiData.weekdays ? weekdaysFromApi(apiData.weekdays) : emptyWeekdays,
    existingSpecialDays: apiData.specialDays
      ? apiData.specialDays.map((_) => convertSpecialDay(_))
      : [],
    newSpecialDays: [],
  };
};

export const toBinaryOpeningHoursDays = (date: Date): WeekDaysBinary => {
  switch (date.getDay()) {
    case 0:
      return WeekDaysBinary.Monday;
    case 1:
      return WeekDaysBinary.Tuesday;
    case 2:
      return WeekDaysBinary.Wednesday;
    case 3:
      return WeekDaysBinary.Thursday;
    case 4:
      return WeekDaysBinary.Friday;
    case 5:
      return WeekDaysBinary.Saturday;
    case 6:
      return WeekDaysBinary.Sunday;
  }

  throw Error("Undefined day in date.");
};

export const convertToUpdateApiModel = (
  branchId: number,
  employeeId: number | undefined,
  hasOwnRegularOpeningTimes: boolean | undefined,
  openingHours: OpeningHoursState,
): ApiUpdateOpeningHoursWithBranchAndEmployee => {
  const data: ApiUpdateOpeningHours[] = [];

  for (const day of allWeekdays) {
    const thisDay = openingHours.weekdays[day];
    if (
      !thisDay ||
      (!thisDay.startTime && !thisDay.endTime && !thisDay.pauseStartTime && !thisDay.pauseEndTime)
    ) {
      continue;
    }

    data.push({
      startTime: thisDay.startTime ? toApiTimeString(thisDay.startTime) : defaultEmptyTime,
      endTime: thisDay.endTime ? toApiTimeString(thisDay.endTime) : defaultEmptyTime,
      pauseStartTime: thisDay.pauseStartTime
        ? toApiTimeString(thisDay.pauseStartTime)
        : defaultEmptyTime,
      pauseEndTime: thisDay.pauseEndTime ? toApiTimeString(thisDay.pauseEndTime) : defaultEmptyTime,

      weekDay: weekDayToBinary(thisDay.weekDay),
      priority: employeeId ? OpeningHoursPriority.EmployeeLow : OpeningHoursPriority.CompanyLow,

      validFromDate: toApiDateString(DateTime.utc(2010, 1, 1)),
      validToDate: toApiDateString(DateTime.utc(2099, 12, 31)),
      updatedAt: toApiDateTimeString(DateTime.utc()),
    });
  }

  for (let specialDay of openingHours.existingSpecialDays) {
    data.push(convertSpecialDaysToApiModel(specialDay));
  }

  return {
    branchId: branchId,
    employeeId: employeeId,
    hasOwnRegularOpeningTimes: hasOwnRegularOpeningTimes,
    openingTimes:
      hasOwnRegularOpeningTimes === true || hasOwnRegularOpeningTimes === undefined ? data : [],
  };
};

export const convertToCreateApiModel = (
  branchId: number,
  employeeId: number | undefined,
  specialDays: OpeningHoursSpecialDay[],
): ApiCreateSpecialDaysOpeningHours => {
  return {
    branchId,
    employeeId,
    openingTimes: specialDays.map((_) => convertSpecialDaysToApiModel(_)),
  };
};
