import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SelectEmployee from "../employees/SelectEmployee";
import { Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Employee } from "../../models/Employee";

interface Props {
  showPreviousWeek: () => void;
  showNextWeek: () => void;
  filterEmployee: (employee: Employee | undefined) => void;
}

const CalendarNavigation: FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isAtLeastLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Stack direction={{ xs: "column", sm: "row" }} gap={1}>
      <SelectEmployee onEmployeeSelect={props.filterEmployee} />

      <Stack direction={"row"} alignItems={"center"}>
        <Button onClick={props.showPreviousWeek} color={"light"} sx={{ mx: 0.5, height: 1 }}>
          <NavigateBeforeIcon sx={{ mr: isAtLeastLargeScreen ? 1 : 0 }} />
          {isAtLeastLargeScreen && t("pages.calendar.previousWeek")}
        </Button>
        <Button onClick={props.showNextWeek} color={"light"} sx={{ mx: 0.5, height: 1 }}>
          {isAtLeastLargeScreen && t("pages.calendar.nextWeek")}
          <NavigateNextIcon sx={{ ml: isAtLeastLargeScreen ? 1 : 0 }} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default CalendarNavigation;
