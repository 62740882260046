import React, { FC, MouseEvent, useContext, useState } from "react";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { Branch } from "../../../models/Branch";
import { SET_BRANCH } from "../../../contexts/UserSettingsReducer";
import { useTranslation } from "react-i18next";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SelectBranch: FC = () => {
  const { t } = useTranslation();
  const { dispatch: userSettingsDispatch, state: userSettings } = useContext(UserSettingsContext);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleOpenBranchSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseBranchSelect = () => {
    setAnchorElement(null);
  };

  const selectBranch = (branch: Branch) => {
    userSettingsDispatch({ type: SET_BRANCH, value: branch });
    handleCloseBranchSelect();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Button
        onClick={handleOpenBranchSelect}
        color={"light"}
        size={"small"}
        sx={{ boxShadow: "none" }}
        endIcon={<ExpandMoreIcon />}
      >
        {userSettings.branch
          ? userSettings.branch.name ?? <i>{t("pages.branches.branchWithoutName")}</i>
          : t("components.nav.selectBranch")}
      </Button>

      <Menu
        sx={{ mt: "45px", p: 0 }}
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElement)}
        onClose={handleCloseBranchSelect}
        PaperProps={{ sx: { p: 1 } }}
      >
        {userSettings.branches?.map((_) => (
          <MenuItem key={_.id} onClick={() => selectBranch(_)}>
            <Typography textAlign="center">
              {_.name ?? <i>{t("pages.branches.branchWithoutName")}</i>}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SelectBranch;
