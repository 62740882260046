import { DateTime } from "luxon";
import { toApiDateString } from "./DateTimeService";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const bookinatorUrl = "https://bookinator.sk";
export const bookinatorPublicFormBaseUrl = "https://rezervacie.bookinator.sk";
export const bookinatorPrivacyPolicyUrl = "https://bookinator.sk/ochrana-osobnych-udajov";
export const bookinatorTermsAndConditionsUrl = "https://bookinator.sk/obchodne-podmienky";

export const apiUrls = {
  user: "auth/currentuser",
  login: "auth/login",
  register: "auth/registeruser",
  confirmAccount: (userId: string, code: string) =>
    `auth/confirmation?userId=${userId}&code=${code}`,
  requestResetPasswordEmail: (email: string) => `auth/forgotPassword?email=${email}`,
  decodeResetPasswordCode: (code: string) => `auth/resetPasswordCode?code=${code}`,
  resetPassword: "auth/resetPassword",

  employees: (employeeId?: number) => {
    let url = "employees";
    if (employeeId) {
      url += `/${employeeId}`;
    }

    return url;
  },
  employeesOfBranch: (branchId: number) => `employees?branchId=${branchId}`,
  reservationsCalendar: (
    branchId: number,
    dateFrom: DateTime,
    dateTo: DateTime,
    employeeId: number | undefined,
  ): string => {
    const dateFormat = "yyyy-MM-dd";
    const dateFromString = dateFrom.toFormat(dateFormat);
    const dateToString = dateTo.toFormat(dateFormat);
    const employeeIdParam = employeeId ? `&employeeId=${employeeId}` : "";

    return `reservations/calendar?branchId=${branchId}&from=${dateFromString}&until=${dateToString}${employeeIdParam}`;
  },
  reservationTypes: (reservationTypeId?: number) =>
    `reservationtypes${reservationTypeId ? `/${reservationTypeId}` : ""}`,
  reservationTypesOfBranch: (branchId: number) => `reservationtypes?branchId=${branchId}`,
  branches: (branchId?: number) => `branches${branchId ? `/${branchId}` : ""}`,
  branchesOfCompany: (companyId: number) => `branches?companyId=${companyId}`,
  logoOfBranch: (branchId: number) => `branches/${branchId}/logo`,
  logoOfBranchNoCache: (branchId: number) =>
    `${apiUrls.logoOfBranch(branchId)}?cache=${new Date().getTime()}`,
  reservations: (reservationId?: number) => {
    let url = "reservations";
    if (reservationId) {
      url += `/${reservationId}`;
    }

    return url;
  },

  openingTimes: "openingtimes",
  openingHoursOfBranch: (branchId: number, from: DateTime, to: DateTime) =>
    `openingtimes/branch/${branchId}?from=${toApiDateString(from)}&until=${toApiDateString(to)}`,
  workingHoursOfEmployee: (branchId: number, from: DateTime, to: DateTime) =>
    `openingtimes/employee/${branchId}?from=${toApiDateString(from)}` +
    `&until=${toApiDateString(to)}`,
};

export const urls = {
  home: "/",
  dashboard: "/dashboard",
  calendar: "/calendar",
  companyBranches: "/company/branches",
  companyEmployees: "/company/employees",
  companyReservationTypes: "/company/reservation-types",
  settings: "/settings",
  profile: "/profile",
  login: "/login",
  registerForm: "/register",
  registerSuccessful: "/register-successful",
  confirmAccount: "/confirm-account",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",

  api: apiUrls,
};

export const externalUrls = {
  publicForm: (branchId: number) => `${bookinatorPublicFormBaseUrl}/branch/${branchId}`,
};
