import { Reservation } from "../models/Reservation";
import { DateTime } from "luxon";
import type { ReservationCalendarBlock } from "../components/calendar/CalendarEmployeeReservations";
import { CalendarBlockType } from "../components/calendar/CalendarEmployeeReservations";
import { OpeningHoursWithPause } from "../contexts/OpeningHoursContext";
import { localToUTC } from "./DateTimeService";
import { setTimeToDateTime, Time } from "../utils/timeUtils";

const timeBlockMinutesDuration = 30;

const combineDateAndTimeUTC = (date: DateTime, time: DateTime) =>
  DateTime.utc(date.year, date.month, date.day, time.hour, time.minute, time.second);

const differenceInMinutes = (second: DateTime, first: DateTime): number =>
  localToUTC(second).diff(localToUTC(first), "minutes").as("minutes");

const getWidthOfBlock = (
  lastBlockEndsAt: DateTime,
  currentBlockEndsAt: DateTime,
  hourRemWidth: number,
): number => {
  if (lastBlockEndsAt > currentBlockEndsAt) {
    throw new Error(
      `LastBlockEndsAt (${lastBlockEndsAt}, ${lastBlockEndsAt.zoneName}) cannot be greater than CurrentBlockEndsAt (${currentBlockEndsAt}, ${currentBlockEndsAt.zoneName})`,
    );
  }

  let minutesDiff = differenceInMinutes(currentBlockEndsAt, lastBlockEndsAt);

  return hourRemWidth * (minutesDiff / 60);
};

const getFirstRoundedTimeAfter = (currentTime: DateTime): DateTime => {
  let minutesResult =
    (currentTime.minute +
      (timeBlockMinutesDuration - (currentTime.minute % timeBlockMinutesDuration))) %
    60;
  let hoursResult = currentTime.hour;

  if (minutesResult <= currentTime.minute) hoursResult++;

  return DateTime.utc(
    currentTime.year,
    currentTime.month,
    currentTime.day,
    hoursResult,
    minutesResult,
    0,
  );
};

const createCalendarBlock = (
  start: DateTime,
  end: DateTime,
  type: CalendarBlockType,
  hourRemWidth: number,
  reservation?: Reservation,
) => {
  if (type !== CalendarBlockType.Reservation && reservation) {
    throw new Error("When creating block with reservation, type must be 'Reservation'");
  }

  return {
    displayRemWidth: getWidthOfBlock(start, end, hourRemWidth),
    startingAt: start,
    endingAt: end,
    reservation: reservation,
    type: type,
  };
};

export const getCalendarRowBlocks = (
  reservations: Reservation[],
  day: DateTime,
  timelineStartTime: Time,
  timelineEndTime: Time,
  openingHours: OpeningHoursWithPause | undefined,
  hourRemWidth: number,
): { reservationBlocks: ReservationCalendarBlock[]; notFittingReservations: Reservation[] } => {
  const timelineStartDateTime = setTimeToDateTime(day.toLocal(), timelineStartTime).toUTC();
  const timelineEndDateTime = setTimeToDateTime(day.toLocal(), timelineEndTime).toUTC();

  if (!openingHours) {
    return {
      reservationBlocks: [
        createCalendarBlock(
          timelineStartDateTime,
          timelineEndDateTime,
          CalendarBlockType.EmptyClosed,
          hourRemWidth,
        ),
      ],
      notFittingReservations: reservations,
    };
  }

  if (!openingHours.startTime || !openingHours.endTime) {
    throw new Error("Opening hours have not set start time or end time");
  }

  let blocks: ReservationCalendarBlock[] = [];
  let notFittingReservations: Reservation[] = [];

  blocks.push(
    createCalendarBlock(
      timelineStartDateTime,
      openingHours.startTime,
      CalendarBlockType.EmptyClosed,
      hourRemWidth,
    ),
  );

  if (openingHours.pauseStartTime && openingHours.pauseEndTime) {
    const resultBeforePause = getCalendarRowWithoutClosedBlocks(
      reservations,
      day,
      openingHours.startTime,
      openingHours.pauseStartTime,
      hourRemWidth,
    );
    blocks.push(...resultBeforePause.reservationBlocks);
    notFittingReservations.push(...resultBeforePause.notFittingReservations);

    blocks.push(
      createCalendarBlock(
        openingHours.pauseStartTime,
        openingHours.pauseEndTime,
        CalendarBlockType.Pause,
        hourRemWidth,
      ),
    );

    const resultAfterPause = getCalendarRowWithoutClosedBlocks(
      reservations,
      day,
      openingHours.pauseEndTime,
      openingHours.endTime,
      hourRemWidth,
    );
    blocks.push(...resultAfterPause.reservationBlocks);
    notFittingReservations.push(...resultAfterPause.notFittingReservations);
  } else {
    const resultWhenOpened = getCalendarRowWithoutClosedBlocks(
      reservations,
      day,
      openingHours.startTime,
      openingHours.endTime,
      hourRemWidth,
    );
    blocks.push(...resultWhenOpened.reservationBlocks);
    notFittingReservations.push(...resultWhenOpened.notFittingReservations);
  }

  blocks.push(
    createCalendarBlock(
      openingHours.endTime,
      timelineEndDateTime,
      CalendarBlockType.EmptyClosed,
      hourRemWidth,
    ),
  );

  return {
    reservationBlocks: blocks,
    notFittingReservations: notFittingReservations,
  };
};

export const getCalendarRowWithoutClosedBlocks = (
  reservations: Reservation[],
  day: DateTime,
  startTime: DateTime,
  endTime: DateTime,
  hourRemWidth: number,
): { reservationBlocks: ReservationCalendarBlock[]; notFittingReservations: Reservation[] } => {
  let lastShownDateTime = combineDateAndTimeUTC(day, startTime);
  let endDateTime = combineDateAndTimeUTC(day, endTime);

  let blocks: ReservationCalendarBlock[] = [];
  let notFittingReservations: Reservation[] = [];

  for (let reservation of reservations) {
    if (reservation.startingAt < lastShownDateTime || reservation.endingAt > endDateTime) {
      notFittingReservations.push(reservation);

      continue;
    }

    while (
      differenceInMinutes(reservation.startingAt, lastShownDateTime) > timeBlockMinutesDuration
    ) {
      let firstRoundedTimeAfter = getFirstRoundedTimeAfter(lastShownDateTime);
      let reservationBlock: ReservationCalendarBlock = {
        displayRemWidth: getWidthOfBlock(lastShownDateTime, firstRoundedTimeAfter, hourRemWidth),
        startingAt: lastShownDateTime,
        endingAt: firstRoundedTimeAfter,
        reservation: undefined,
        type: CalendarBlockType.EmptyOpen,
      };

      blocks.push(reservationBlock);
      lastShownDateTime = firstRoundedTimeAfter;
    }

    if (differenceInMinutes(reservation.startingAt, lastShownDateTime) > 0) {
      let reservationBlock: ReservationCalendarBlock = {
        displayRemWidth: getWidthOfBlock(lastShownDateTime, reservation.startingAt, hourRemWidth),
        startingAt: lastShownDateTime,
        endingAt: reservation.startingAt,
        reservation: undefined,
        type: CalendarBlockType.EmptyOpen,
      };

      blocks.push(reservationBlock);
      lastShownDateTime = reservation.startingAt;
    }

    blocks.push({
      reservation: reservation,
      displayRemWidth: getWidthOfBlock(reservation.startingAt, reservation.endingAt, hourRemWidth),
      startingAt: reservation.startingAt,
      endingAt: reservation.endingAt,
      type: CalendarBlockType.Reservation,
    });

    lastShownDateTime = reservation.endingAt;
  }

  while (differenceInMinutes(endDateTime, lastShownDateTime) > timeBlockMinutesDuration) {
    let firstRoundedTimeAfter = getFirstRoundedTimeAfter(lastShownDateTime);
    let reservationBlock: ReservationCalendarBlock = {
      displayRemWidth: getWidthOfBlock(lastShownDateTime, firstRoundedTimeAfter, hourRemWidth),
      startingAt: lastShownDateTime,
      endingAt: firstRoundedTimeAfter,
      reservation: undefined,
      type: CalendarBlockType.EmptyOpen,
    };

    blocks.push(reservationBlock);
    lastShownDateTime = firstRoundedTimeAfter;
  }

  if (differenceInMinutes(endDateTime, lastShownDateTime) > 0) {
    let reservationBlock: ReservationCalendarBlock = {
      displayRemWidth: getWidthOfBlock(lastShownDateTime, endDateTime, hourRemWidth),
      startingAt: lastShownDateTime,
      endingAt: endDateTime,
      reservation: undefined,
      type: CalendarBlockType.EmptyOpen,
    };

    blocks.push(reservationBlock);
  }

  return {
    reservationBlocks: blocks,
    notFittingReservations: notFittingReservations,
  };
};
