/* Action constants */
import { UserSettingsState } from "./UserSettingsContext";
import { Branch } from "../models/Branch";
import { Employee } from "../models/Employee";

export const SET_BRANCH = "user-settings/set-branch";
export const SET_EMPLOYEE = "user-settings/set-employee";

/* Action interfaces */
export interface SetBranchAction {
  type: typeof SET_BRANCH;
  value: Branch | undefined;
}

export interface SetEmployeeAction {
  type: typeof SET_EMPLOYEE;
  value: Employee;
}

/* Action functions */
const setBranch = (branch: Branch | undefined, state: UserSettingsState): UserSettingsState => {
  return { ...state, branch: branch };
};

const setEmployee = (employee: Employee, state: UserSettingsState): UserSettingsState => {
  return { ...state, employee: employee };
};

export type UserSettingsActionType = SetBranchAction | SetEmployeeAction;

export const UserSettingsReducer = (
  state: UserSettingsState,
  action: UserSettingsActionType,
): UserSettingsState => {
  switch (action.type) {
    case SET_BRANCH:
      return setBranch(action.value, state);
    case SET_EMPLOYEE:
      return setEmployee(action.value, state);
    default:
      return state;
  }
};
