import React, { FC, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../common/inputs/TextInput";
import { mailPattern, unwrap } from "../../helpers/utils";
import { Branch } from "../../models/Branch";
import useAxios from "axios-hooks";
import { urls } from "../../helpers/urls";
import { useAuthContext } from "../../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import BranchLogoUploader from "./BranchLogoUploader";
import BranchOtherSettings from "./BranchOtherSettings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IBranchForm {
  name: string;
  address: string;
  phoneNumber: string;
  email: string;
  isIndividualWorkScheduleAllowed: boolean;
  logoBase64: string | undefined;
}

interface Props {
  branch?: Branch;
  onSave: () => void;
  onDelete: () => void;
  onCancel: () => void;
}

const BranchForm: FC<Props> = ({ branch, onSave, onDelete, onCancel }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user: userFromContext } = useAuthContext();
  const user = unwrap(userFromContext);

  const { register, handleSubmit, errors, control, setValue } = useForm<IBranchForm>({
    defaultValues: branch
      ? {
          address: branch.address,
          email: branch.email,
          name: branch.name,
          phoneNumber: branch.phoneNumber,
          isIndividualWorkScheduleAllowed: branch.isIndividualWorkScheduleAllowed,
          logoBase64: undefined,
        }
      : undefined,
  });

  const [{ loading: isSavingBranch }, saveBranch] = useAxios(
    { url: urls.api.branches(branch?.id) },
    { manual: true },
  );
  const [, deleteBranchAxios] = useAxios({ method: "DELETE" }, { manual: true });

  const isEditingBranch = !!branch?.id;

  const createBranch = async (formData: IBranchForm) => {
    const requestBody = {
      ...formData,
      companyId: user.adminOfCompanyId,
    };

    return saveBranch({ method: "POST", data: requestBody });
  };

  const updateBranch = async (formData: IBranchForm, branchId: number) => {
    const requestBody = {
      ...formData,
      companyId: user.adminOfCompanyId,
      id: branchId,
    };

    return saveBranch({ method: "PUT", data: requestBody });
  };

  const onSubmit = async (data: IBranchForm) => {
    try {
      if (branch?.id) {
        await updateBranch(data, branch.id);
      } else {
        await createBranch(data);
      }

      enqueueSnackbar(t("common.savedSuccessfully"), { variant: "success" });
      onSave();
    } catch (e) {
      enqueueSnackbar(t("common.savingFailedTryAgain"), { variant: "error" });
    }
  };

  const deleteBranch = async () => {
    if (!branch?.id) {
      return;
    }

    try {
      await deleteBranchAxios({ url: urls.api.branches(branch.id) });
      enqueueSnackbar(t("common.deletedSuccessfully"), { variant: "success" });

      onDelete();
    } catch (e) {
      enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
    }
  };

  useEffect(() => {
    register("logoBase64");
  }, [register]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="name"
          id="name"
          label={t("common.name")}
          error={errors.name?.message}
          isSolid={false}
        />

        <BranchLogoUploader branch={branch} onChange={(_) => setValue("logoBase64", _)} />

        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="address"
          id="address"
          label={t("pages.branches.address")}
          error={errors.address?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
          })}
          name="phoneNumber"
          id="phoneNumber"
          label={t("common.phone")}
          error={errors.phoneNumber?.message}
          isSolid={false}
        />
        <TextInput
          ref={register({
            required: t<string>("errors.fieldCannotBeEmpty"),
            pattern: {
              value: mailPattern,
              message: t("errors.pleaseEnterValidEmail"),
            },
          })}
          name="email"
          id="email"
          label={t("common.email")}
          error={errors.email?.message}
          isSolid={false}
        />

        <FormControlLabel
          control={
            <Controller
              name={"isIndividualWorkScheduleAllowed"}
              control={control}
              render={({ onChange, value }) => (
                <Checkbox onChange={(_) => onChange(_.target.checked)} checked={value} />
              )}
            />
          }
          label={t<string>("pages.branches.allowEmployeesToHaveCustomWorkingTime")}
        />
      </div>

      {isEditingBranch && (
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("pages.branches.showOtherSettings")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BranchOtherSettings deleteBranch={deleteBranch} />
          </AccordionDetails>
        </Accordion>
      )}

      <div className="d-flex justify-content-start align-items-center mt-4">
        <LoadingButton
          color={"primary"}
          variant={"contained"}
          type="submit"
          className={"mr-2"}
          loading={isSavingBranch}
        >
          {t("common.save")}
        </LoadingButton>

        <Button onClick={onCancel} variant={"outlined"}>
          {t("common.cancel")}
        </Button>
      </div>
    </form>
  );
};

export default BranchForm;
