import React, { FC } from "react";
import { appName } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {
  bookinatorPrivacyPolicyUrl,
  bookinatorTermsAndConditionsUrl,
  bookinatorUrl,
} from "../../helpers/urls";
import packageJson from "../../../package.json";

interface Props {}

const Footer: FC<Props> = (props) => {
  const { t } = useTranslation();

  const getCopyrightYear = (): string => {
    const appFirstYear = 2022;
    const currentYear = new Date().getFullYear();

    if (appFirstYear === currentYear) {
      return appFirstYear.toString();
    }

    return `${appFirstYear} - ${currentYear}`;
  };

  return (
    <footer className="footer mt-auto footer-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6 small">
            ©{" "}
            <a href={bookinatorUrl} target={"_blank"}>
              {appName}
            </a>{" "}
            {getCopyrightYear()} | v{packageJson.version}
          </div>

          <div className="col-md-6 text-md-right small">
            <a href={bookinatorPrivacyPolicyUrl} target={"_blank"}>
              {t("components.footer.privacyPolicy")}
            </a>
            &nbsp;·&nbsp;
            <a href={bookinatorTermsAndConditionsUrl} target={"_blank"}>
              {t("components.footer.termsAndConditions")}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
