import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseStep from "./BaseStep";
import {
  CustomerForm,
  ReservationFormData,
  ReservationFormStateContext,
} from "../ReservationFormStateProvider";
import styles from "./Step4.module.scss";
import TextInput from "../../../common/inputs/TextInput";
import useAxios from "axios-hooks";
import { urls } from "../../../helpers/urls";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { toApiDateTimeString } from "../../../helpers/DateTimeService";
import { useSnackbar } from "notistack";

interface Props {
  onSave: () => void;
}

const Step4: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { state: userSettings } = useContext(UserSettingsContext);
  const {
    formState: { data },
  } = useContext(ReservationFormStateContext);

  const { handleSubmit, register, errors } = useForm<ReservationFormData>({ defaultValues: data });

  const [{ loading: isSavingReservation }, saveReservation] = useAxios(
    { url: urls.api.reservations(data.id) },
    { manual: true },
  );

  const prepareRequestData = (reservationFormData: ReservationFormData) => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when preparing request data.");
    }

    let date = reservationFormData.date;
    if (!date) {
      return;
    }

    let dateTime = date.set({
      second: 0,
    });
    delete reservationFormData.date;

    return {
      ...reservationFormData,
      startingAt: toApiDateTimeString(dateTime),
      branchId: userSettings.branch.id,
    };
  };

  const createReservation = async (reservationFormData: ReservationFormData) => {
    await saveReservation({ method: "POST", data: prepareRequestData(reservationFormData) });
  };

  const updateReservation = async (reservationFormData: ReservationFormData) => {
    await saveReservation({
      method: "PUT",
      data: { ...prepareRequestData(reservationFormData), id: reservationFormData.id },
    });
  };

  const onSubmit = async (formData: { customer: CustomerForm }) => {
    let finalFormData = { ...data, ...formData };

    try {
      if (!data.id) {
        await createReservation(finalFormData);
      } else {
        await updateReservation(finalFormData);
      }

      enqueueSnackbar(t("common.savedSuccessfully"), { variant: "success" });
      props.onSave();
    } catch {
      enqueueSnackbar(t("common.savingFailedTryAgain"), { variant: "error" });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <BaseStep title={t("components.reservationForm.contact")} isSaving={isSavingReservation}>
        <div className={styles.wrapper}>
          <TextInput
            ref={register({ required: t<string>("errors.fieldCannotBeEmpty") })}
            name="customer.firstName"
            label={t("components.reservationForm.firstName")}
            id="firstName"
            error={errors.customer?.firstName?.message}
            defaultValue={data.customer?.firstName}
            isSolid={false}
          />

          <TextInput
            ref={register({ required: t<string>("errors.fieldCannotBeEmpty") })}
            name="customer.lastName"
            label={t("components.reservationForm.lastName")}
            id="lastName"
            error={errors.customer?.lastName?.message}
            defaultValue={data.customer?.lastName}
            isSolid={false}
          />

          <TextInput
            ref={register({
              required: t<string>("errors.fieldCannotBeEmpty"),
              pattern: {
                value: /^\w+([.-]?\w+)*(\+\w+([.-]?\w+)*)?@\w+([.-]?\w+)*(.\w{2,3})+$/,
                message: t<string>("errors.pleaseEnterValidEmail"),
              },
            })}
            name="customer.email"
            label={t("common.email")}
            id="email"
            error={errors.customer?.email?.message}
            defaultValue={data.customer?.email}
            isSolid={false}
          />

          <TextInput
            ref={register({ required: t<string>("errors.fieldCannotBeEmpty") })}
            name="customer.phoneNumber"
            label={t("common.phone")}
            id="phoneNumber"
            error={errors.customer?.phoneNumber?.message}
            defaultValue={data.customer?.phoneNumber}
            isSolid={false}
          />
        </div>
      </BaseStep>
    </Form>
  );
};

export default Step4;
