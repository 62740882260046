import React, { useContext, useEffect } from "react";
import cx from "classnames";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseStep from "./BaseStep";
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from "../ReservationFormStateProvider";
import { Spinner } from "react-bootstrap";
import useAxios from "axios-hooks";
import { ReservationType } from "../../../models/ReservationType";
import { urls } from "../../../helpers/urls";
import "./Step1.scss";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";

const Step1: React.FC = () => {
  const { state: userSettings } = useContext(UserSettingsContext);

  const [{ data: reservationTypes, loading }, reloadReservationTypes] = useAxios<ReservationType[]>(
    {},
    { manual: true },
  );
  const { t } = useTranslation();

  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext);
  const { handleSubmit, control, errors } = useForm<ReservationFormData>({ defaultValues: data });

  useEffect(() => {
    if (!userSettings.branch) {
      return;
    }

    reloadReservationTypes({ url: urls.api.reservationTypesOfBranch(userSettings.branch.id) });
  }, [reloadReservationTypes, userSettings.branch]);

  const onSubmit = (formData: { reservationTypeId: number }) => {
    dispatch(setReservationFormDataAction({ ...data, ...formData }));
    dispatch(setReservationFormStepAction(2));
  };

  if (loading) {
    return (
      <Spinner animation="border" variant={"dark"} role="status" className="m-5">
        <span className="sr-only">{t("common.loading")}...</span>
      </Spinner>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t("components.reservationForm.selectService")}
        error={errors.reservationTypeId?.message}
      >
        <Controller
          defaultValue={data.reservationTypeId ?? ""}
          render={({ onChange, value }) => (
            <div className={"row px-2"}>
              {reservationTypes?.map((type) => (
                <div className={"col-12 col-md-6 p-2"} key={type.id}>
                  <button
                    key={type.id}
                    onClick={() => {
                      onChange(type.id);
                    }}
                    className={cx("item", "btn", "btn-outline-dark", {
                      active: type.id === value,
                    })}
                  >
                    {type.name}
                  </button>
                </div>
              ))}
            </div>
          )}
          name="reservationTypeId"
          rules={{
            required: t("errors.pleaseSelectOneOption") as string,
          }}
          control={control}
        />
      </BaseStep>
    </form>
  );
};

export default Step1;
