import React, { FC, useEffect } from "react";
import LoginLayout from "../components/layouts/LoginLayout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { ResetPasswordViewModel } from "../models/api/ResetPasswordViewModel";
import TextInput from "../common/inputs/TextInput";
import { PASSWORD_MIN_LENGTH, PASSWORD_VALIDATION_REGEX } from "../utils/constants";
import { Card } from "react-bootstrap";

interface ResetPasswordForm {
  newPassword: string;
  repeatedPassword: string;
}

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, watch, formState } = useForm<ResetPasswordForm>();

  const queryParamsSearch = useLocation().search;
  const history = useHistory();

  const email = new URLSearchParams(queryParamsSearch).get("email") ?? "";
  const originalCode = new URLSearchParams(queryParamsSearch).get("code") ?? "";

  const [{ data: decodedCode, loading: decodeLoading, error: decodeError }, decodeCode] = useAxios<
    string
  >({}, { manual: true });
  const [{ loading: resetPasswordLoading, error: resetPasswordError }, resetPassword] = useAxios(
    { method: "POST" },
    { manual: true },
  );

  useEffect(() => {
    decodeCode({ url: urls.api.decodeResetPasswordCode(originalCode) });
  }, [decodeCode, originalCode]);

  const onSubmit = async (data: ResetPasswordForm) => {
    const body: ResetPasswordViewModel = {
      email: email,
      password: data.newPassword,
      code: decodedCode,
    };

    resetPassword({ url: urls.api.resetPassword, data: body });
  };

  const goToLogin = () => {
    history.push(urls.login);
  };

  const isLoading = decodeLoading || resetPasswordLoading;
  const isError = !isLoading && (!!decodeError || !!resetPasswordError);

  return (
    <LoginLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4 offset-md-4 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className="my-2">{t("pages.forgotPassword.passwordRecovery")}</h2>
              </Card.Header>

              <Card.Body>
                {isLoading && (
                  <Box sx={{ display: "flex", width: 1, justifyContent: "center", mb: 2 }}>
                    <CircularProgress />
                  </Box>
                )}

                {isError && (
                  <Alert severity={"error"} sx={{ mb: 3 }}>
                    <Typography>{t("common.somethingWentWrongPleaseTryAgainLater")}</Typography>
                  </Alert>
                )}

                {formState.isSubmitSuccessful && !isLoading && !isError && (
                  <Stack direction={"column"}>
                    <Alert severity={"success"} sx={{ mb: 3 }}>
                      <Typography>
                        {t("pages.forgotPassword.yourPasswordWasChangedYouCanLogIn")}
                      </Typography>
                    </Alert>

                    <Button onClick={goToLogin}>{t("pages.forgotPassword.backToLogin")}</Button>
                  </Stack>
                )}

                {decodedCode && !formState.isSubmitSuccessful && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                      ref={register({
                        required: t<string>("pages.register.pleaseEnterYourPassword"),
                        minLength: {
                          value: PASSWORD_MIN_LENGTH,
                          message: t("pages.register.passwordMinLength", {
                            minLength: PASSWORD_MIN_LENGTH,
                          }),
                        },
                        pattern: {
                          value: PASSWORD_VALIDATION_REGEX,
                          message: t("pages.register.passwordRequiredChars"),
                        },
                      })}
                      id="newPassword"
                      name="newPassword"
                      label={t("common.password")}
                      type="password"
                      error={errors.newPassword?.message}
                    />

                    <TextInput
                      ref={register({
                        required: t<string>("pages.register.pleaseRepeatYourPasswordOnceAgain"),
                        validate: (value) =>
                          value === watch("newPassword") ||
                          t<string>("pages.register.passwordsShouldMatch"),
                      })}
                      id="password"
                      name="repeatedPassword"
                      label={t("pages.register.passwordOnceAgain")}
                      type="password"
                      error={errors.repeatedPassword?.message}
                    />

                    <Stack direction={"row"} sx={{ mt: 3 }}>
                      <Button type={"submit"} variant={"contained"}>
                        {t("pages.forgotPassword.resetPassword")}
                      </Button>

                      <Button onClick={goToLogin} sx={{ ml: 1 }}>
                        {t("pages.forgotPassword.backToLogin")}
                      </Button>
                    </Stack>
                  </form>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ResetPasswordPage;
