import axios from "axios";
import { apiBaseUrl } from "./helpers/urls";

const axiosInstance = axios.create({ baseURL: apiBaseUrl });

export const setAccessTokenToAxios = (accessToken?: string) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  } else {
    axiosInstance.defaults.headers.common["Authorization"] = undefined;
  }
};

export default axiosInstance;
