import React, { createContext, Dispatch, FC, useReducer } from "react";
import { OpeningHoursActionType, OpeningHoursReducer } from "./OpeningHoursReducer";
import { DateTime } from "luxon";

export enum OpeningHoursPriority {
  CompanyLow = 1,
  CompanyMedium = 2,
  CompanyUpperMedium = 3,
  CompanyHighest = 5,
  EmployeeLow = 6,
  EmployeeMedium = 7,
  EmployeeUpperMedium = 8,
  EmployeeHigh = 10,
}

export interface OpeningHoursWithPause {
  startTime?: DateTime;
  endTime?: DateTime;
  pauseStartTime?: DateTime;
  pauseEndTime?: DateTime;
}

export type OpeningHoursWeekDay = OpeningHoursWithPause & {
  weekDay: WeekDays;
};

export type OpeningHoursSpecialDay = OpeningHoursWithPause & {
  priority: number;
  validFromDate?: DateTime;
  validToDate?: DateTime;
};

export enum WeekDays {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export type WeekDaysObject = {
  [key in WeekDays]: OpeningHoursWeekDay | undefined;
};
export const allWeekdays = (Object.keys(WeekDays) as (keyof typeof WeekDays)[]).map(
  (_) => WeekDays[_],
);

export interface OpeningHoursState {
  weekdays: WeekDaysObject;
  existingSpecialDays: (OpeningHoursSpecialDay & { id: number })[];
  newSpecialDays: OpeningHoursSpecialDay[];
}

export const emptyWeekdays: WeekDaysObject = {
  [WeekDays.Monday]: undefined,
  [WeekDays.Tuesday]: undefined,
  [WeekDays.Wednesday]: undefined,
  [WeekDays.Thursday]: undefined,
  [WeekDays.Friday]: undefined,
  [WeekDays.Saturday]: undefined,
  [WeekDays.Sunday]: undefined,
};

export const getEmptySpecialDay = (
  specialDayFor: "branch" | "employee",
): OpeningHoursSpecialDay => {
  return {
    priority:
      specialDayFor === "branch"
        ? OpeningHoursPriority.CompanyMedium
        : OpeningHoursPriority.EmployeeMedium,
    validFromDate: DateTime.local().set({ hour: 0, minute: 0, second: 0 }),
  };
};

const initialState: OpeningHoursState = {
  weekdays: emptyWeekdays,
  existingSpecialDays: [],
  newSpecialDays: [],
};

export const OpeningHoursContext = createContext<{
  state: OpeningHoursState;
  dispatch: Dispatch<OpeningHoursActionType>;
}>({
  state: initialState,
  dispatch: () => {},
});

const OpeningHoursContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(OpeningHoursReducer, initialState);

  return (
    <OpeningHoursContext.Provider value={{ state, dispatch }}>
      {children}
    </OpeningHoursContext.Provider>
  );
};

export { OpeningHoursContextProvider };
