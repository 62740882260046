import useAxios from "axios-hooks";
import { EmployeesReservationsCalendar } from "../../models/EmployeesReservationsCalendar";
import { urls } from "../../helpers/urls";
import {
  convertFromApi,
  EmployeesReservationsCalendarApi,
} from "../../models/api/EmployeesReservationsCalendarApi";
import { DateTime } from "luxon";
import { useMemo } from "react";

interface HookResult {
  loading: boolean;
  error: any;
  data: EmployeesReservationsCalendar[];
  reload: () => void;
}

export const useCalendarApi = (
  branchId: number,
  startDate: DateTime,
  endDate: DateTime,
  employeeId: number | undefined,
): HookResult => {
  const [{ data: apiData, loading: isLoadingCalendar, error }, reloadCalendar] = useAxios<
    EmployeesReservationsCalendarApi[]
  >({
    url: urls.api.reservationsCalendar(branchId, startDate, endDate, employeeId),
  });

  const employeeReservationsByDate = useMemo(() => (apiData ?? []).map((_) => convertFromApi(_)), [
    apiData,
  ]);

  return {
    data: employeeReservationsByDate,
    loading: isLoadingCalendar,
    error: error,
    reload: reloadCalendar,
  };
};
