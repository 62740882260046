import React from "react";

type InputElementAttributes = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface CheckboxInputBaseProps extends Omit<InputElementAttributes, "onChange" | "ref"> {
  error?: string;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}

interface CheckboxInputPropsWithLabel extends CheckboxInputBaseProps {
  id: string;
  label: string;
}

interface CheckboxInputPropsWithoutLabel extends CheckboxInputBaseProps {
  id?: string;
  label?: null;
}

type CheckboxInputProps = CheckboxInputPropsWithLabel | CheckboxInputPropsWithoutLabel;

const CheckboxInput = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ error, onChange, disabled, label, id, ...nativeCheckboxProps }, ref) => {
    return (
      <div className="custom-control custom-checkbox custom-control-solid">
        <input
          {...nativeCheckboxProps}
          ref={ref}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.checked);
            }
          }}
          className="custom-control-input"
          id={id}
          disabled={disabled}
          type="checkbox"
        />
        {label && (
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
        )}

        {error && <div className="text-danger text-sm">{error}</div>}
      </div>
    );
  },
);

export default CheckboxInput;
