import React, { FC } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import { urls } from "../../helpers/urls";
import calendarSvg from "../../img/svgs/calendar.svg";

interface Props {}

const MyListItem: FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => (
  <ListItem disablePadding>
    <ListItemButton>
      <ListItemIcon>
        <ChevronRightIcon />
      </ListItemIcon>
      <ListItemText primary={text} onClick={onClick} />
    </ListItemButton>
  </ListItem>
);

const EmptyCalendarHints: FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectTo = (url: string) => {
    history.push(url);
  };

  return (
    <Grid container sx={{ display: "flex", alignItems: "center" }}>
      <Grid item md={6}>
        <img src={calendarSvg} alt={t("pages.calendar.calendar")} className={"px-5 w-75"} />
      </Grid>
      <Grid item md={6}>
        <Paper>
          <Typography variant={"h4"} sx={{ mb: 2 }}>
            {t("pages.calendar.toStartUsingCalendarYouNeedTo")}
          </Typography>

          <List>
            <MyListItem
              text={t("pages.calendar.addAtLeastOneEmployee")}
              onClick={() => redirectTo(urls.companyEmployees)}
            />
            <MyListItem
              text={t("pages.calendar.addAtLeastOneServiceYouProvide")}
              onClick={() => redirectTo(urls.companyReservationTypes)}
            />
            <MyListItem
              text={t("pages.calendar.setBranchOpeningTimes")}
              onClick={() => redirectTo(urls.companyBranches)}
            />
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EmptyCalendarHints;
