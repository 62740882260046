import React from "react";
import cx from "classnames";

type InputElementAttributes = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface TextInputBaseProps extends Omit<InputElementAttributes, "onChange" | "ref"> {
  className?: string;
  disabled?: boolean;
  error?: string;
  onChange?: (value: string) => void;
  isSolid?: boolean;
  endAdornment?: string;
}

interface TextInputPropsWithLabel extends TextInputBaseProps {
  // ID is required if label is set
  id: string;
  label: string;
}

interface TextInputPropsWithoutLabel extends TextInputBaseProps {
  id?: string;
  // Removing these optional nulls, would lead into complicated conditioning inside component
  // This will guarantee that label / onLabelChange are null (or undefined) in case TextInput is used without label, without explicitly setting it to null
  label?: null;
}

// This union will force setting ID in case label is set
type TextInputProps = TextInputPropsWithLabel | TextInputPropsWithoutLabel;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      disabled,
      error,
      onChange,
      id,
      label,
      isSolid = true,
      endAdornment,
      ...nativeInputProps
    },
    ref,
  ) => {
    return (
      <div className={"d-flex flex-column mb-4"}>
        {label && (
          <label className="text-gray-600 small" htmlFor={id}>
            {label}
          </label>
        )}

        <div className={cx("input-group", className)}>
          <input
            {...nativeInputProps}
            className={cx("form-control py-4", {
              "is-invalid": !!error,
              "form-control-solid": !!isSolid,
            })}
            ref={ref}
            onChange={(event) => {
              if (onChange) {
                onChange(event.target.value);
              }
            }}
            disabled={disabled}
            id={id}
          />

          {endAdornment && (
            <div className="input-group-append">
              <span className="input-group-text">{endAdornment}</span>
            </div>
          )}

          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    );
  },
);

export default TextInput;
