import { Reservation } from "../Reservation";
import { parseToUtc } from "../../helpers/DateTimeService";

export interface ReservationApi extends Omit<Reservation, "startingAt" | "endingAt"> {
  startingAt: string;
  endingAt: string;
}

export const convertFromApi = (apiData: ReservationApi): Reservation => {
  return {
    id: apiData.id,
    startingAt: parseToUtc(apiData.startingAt),
    endingAt: parseToUtc(apiData.endingAt),
    notes: apiData.notes,
    employeeId: apiData.employeeId,
    employee: apiData.employee,
    reservationTypeId: apiData.reservationTypeId,
    reservationType: apiData.reservationType,
    customerId: apiData.customerId,
    customer: apiData.customer,
    branchId: apiData.branchId,
    branch: apiData.branch,
  };
};
