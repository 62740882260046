import useAxios from "axios-hooks";
import { apiUrls } from "../../helpers/urls";
import { useEffect } from "react";
import { Buffer } from "buffer";

interface UseBranchLogoResult {
  originalLogoBase64: string | undefined;
  isLoadingLogo: boolean;
}

export const useBranchLogoBase64 = (branchId: number | undefined): UseBranchLogoResult => {
  const [{ data, loading }, fetch] = useAxios<string>(
    {
      url: branchId ? apiUrls.logoOfBranch(branchId) : "",
      responseType: "arraybuffer",
      transformResponse: (response) => {
        if (!response) {
          return undefined;
        }

        return Buffer.from(response, "binary").toString("base64");
      },
    },
    { manual: true },
  );

  useEffect(() => {
    if (!branchId) {
      return;
    }

    fetch();
  }, [branchId, fetch]);

  return {
    originalLogoBase64: data ?? undefined,
    isLoadingLogo: loading,
  };
};
