import React, { FC, useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import BranchList from "../components/branches/BranchList";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { Branch } from "../models/Branch";
import OverlayContainer from "../common/OverlayContainer/OverlayContainer";
import BranchForm from "../components/branches/BranchForm";
import BranchOpeningHoursForm from "../components/opening-hours/branch/BranchOpeningHoursForm";
import { useAuthContext } from "../contexts/AuthContext";
import { unwrap } from "../helpers/utils";
import { UserSettingsContext } from "../contexts/UserSettingsContext";

const BranchesPage: FC = () => {
  const { t } = useTranslation();
  const { user: userFromContext } = useAuthContext();
  const {
    state: { refreshBranches },
  } = useContext(UserSettingsContext);
  const user = unwrap(userFromContext);

  const [form, setForm] = useState<"none" | "new" | "existing" | "opening-hours">("none");
  const [formEditingBranch, setFormEditingBranch] = useState<Branch>();

  const [{ data: branches, loading: isLoadingBranches }, reloadBranches] = useAxios<Branch[]>(
    urls.api.branchesOfCompany(user.adminOfCompanyId),
  );

  const onBranchDelete = () => {
    setForm("none");
    setFormEditingBranch(undefined);
    reloadBranches();
    refreshBranches();
  };

  return (
    <AdminLayout title={t("components.nav.branches")}>
      {isLoadingBranches ? (
        <div className="text-center">
          <Spinner animation="border" variant={"dark"} role="status" className="m-5">
            <span className="sr-only">{t("common.loading")}...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <BranchList
            branches={branches}
            onCreate={() => setForm("new")}
            onEdit={(branch) => {
              setForm("existing");
              setFormEditingBranch(branch);
            }}
            editOpeningHours={(branch) => {
              setForm("opening-hours");
              setFormEditingBranch(branch);
            }}
          />
        </>
      )}

      {form !== "none" && (
        <OverlayContainer
          variant={"wide"}
          title={
            form === "new"
              ? t("pages.branches.addNewBranch")
              : form === "existing"
              ? t("pages.branches.editBranch")
              : t("pages.branches.editOpeningHours")
          }
          onClose={() => {
            setForm("none");
            setFormEditingBranch(undefined);
          }}
        >
          {form !== "opening-hours" ? (
            <BranchForm
              branch={formEditingBranch}
              onSave={() => {
                setForm("none");
                setFormEditingBranch(undefined);
                reloadBranches();
                refreshBranches();
              }}
              onDelete={onBranchDelete}
              onCancel={() => {
                setForm("none");
                setFormEditingBranch(undefined);
              }}
            />
          ) : (
            formEditingBranch && (
              <BranchOpeningHoursForm
                branchId={formEditingBranch.id}
                onSave={() => {
                  setForm("none");
                  setFormEditingBranch(undefined);
                }}
                onCancel={() => {
                  setForm("none");
                  setFormEditingBranch(undefined);
                }}
              />
            )
          )}
        </OverlayContainer>
      )}
    </AdminLayout>
  );
};

export default BranchesPage;
