import React, { FC } from "react";
import cx from "classnames";
import { DatePickerProps, default as ExternalDatePicker } from "react-date-picker";
import "./DatePicker.scss";
import { DateTime } from "luxon";
import {
  getBrowserTimeZone,
  setDateWithoutChangingTime,
  toJSDate,
  utcToLocal,
} from "../../helpers/DateTimeService";

type Props = Omit<DatePickerProps, "value" | "onChange"> & {
  value: DateTime | undefined;
  onChange: (value: DateTime) => void;
  wrapperClassName?: string;
  doNotChangeTimeZone?: boolean;
};

const DatePicker: FC<Props> = (props) => {
  const onChange = (newValue: Date) => {
    const newValueDateTime = DateTime.fromJSDate(newValue, { zone: getBrowserTimeZone() });
    const originalValue = utcToLocal(
      props.value ??
        DateTime.local().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
    );

    props.onChange(setDateWithoutChangingTime(originalValue, newValueDateTime));
  };

  const toJSDateInternal = (dateTime: DateTime | undefined): Date | undefined => {
    if (!dateTime) {
      return undefined;
    }

    const localDateTime = utcToLocal(dateTime);
    return toJSDate(localDateTime);
  };

  return (
    <div className={cx("rounded", props.wrapperClassName)}>
      <ExternalDatePicker
        {...props}
        value={toJSDateInternal(props.value)}
        onChange={onChange}
        className={"form-control"}
        locale={"sk"}
        formatDay={(locale, date) => {
          return date.getDate().toString();
        }}
        clearIcon={null}
      />
    </div>
  );
};

export default DatePicker;
