import React, { useContext, useEffect } from "react";
import cx from "classnames";
import { Controller, useForm } from "react-hook-form";
import "./Step1.scss";
import { useTranslation } from "react-i18next";
import BaseStep from "./BaseStep";
import {
  ReservationFormData,
  ReservationFormStateContext,
  setReservationFormDataAction,
  setReservationFormStepAction,
} from "../ReservationFormStateProvider";
import { Spinner } from "react-bootstrap";
import useAxios from "axios-hooks";
import { Employee } from "../../../models/Employee";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { urls } from "../../../helpers/urls";

const Step2: React.FC = () => {
  const { state: userSettings } = useContext(UserSettingsContext);
  const {
    formState: { data },
    dispatch,
  } = useContext(ReservationFormStateContext);

  // TODO filter by service ID, too
  const [{ data: employees, loading }, reloadEmployees] = useAxios<Employee[]>(
    {},
    { manual: true },
  );
  const { t } = useTranslation();

  const { handleSubmit, control, errors } = useForm<ReservationFormData>({ defaultValues: data });

  useEffect(() => {
    if (!userSettings.branch) {
      return;
    }

    reloadEmployees({ url: urls.api.employeesOfBranch(userSettings.branch.id) });
  }, [reloadEmployees, userSettings.branch]);

  const onSubmit = (formData: { employeeId: number }) => {
    dispatch(setReservationFormDataAction({ ...data, ...formData }));
    dispatch(setReservationFormStepAction(3));
  };

  if (loading) {
    return (
      <Spinner animation="border" variant={"dark"} role="status" className="m-5">
        <span className="sr-only">{t("common.loading")}...</span>
      </Spinner>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseStep
        title={t("components.reservationForm.selectWorker")}
        error={errors.employeeId?.message}
      >
        <Controller
          defaultValue={data.employeeId ?? ""}
          render={({ onChange, value }) => (
            <div className={"row px-2"}>
              {employees?.map((employee) => (
                <div className={"col-12 col-md-6 p-2"} key={employee.id}>
                  <button
                    key={employee.id}
                    onClick={() => onChange(employee.id)}
                    className={cx("item", "btn", "btn-outline-dark", {
                      active: employee.id === value,
                    })}
                  >
                    {employee.firstName} {employee.lastName}
                  </button>
                </div>
              ))}
            </div>
          )}
          name="employeeId"
          rules={{
            required: t("errors.pleaseSelectOneOption") as string,
          }}
          control={control}
        />
      </BaseStep>
    </form>
  );
};

export default Step2;
