import React, { FC, useContext, useEffect, useState } from "react";
import { Employee } from "../../../models/Employee";
import StandardOpeningHours from "../StandardOpeningHours";
import OpeningHoursCalendarForm from "./OpeningHoursCalendarForm";
import { urls } from "../../../helpers/urls";
import useAxios from "axios-hooks";
import { parseJson } from "../../../helpers/utils";
import ExceptionalOpeningHours from "../ExceptionalOpeningHours";
import Preloader from "../../common/Preloader";
import { useTranslation } from "react-i18next";
import { SET_EXISTING_SPECIAL_DAYS, SET_WEEKDAYS } from "../../../contexts/OpeningHoursReducer";
import { OpeningHoursContext } from "../../../contexts/OpeningHoursContext";
import { UserSettingsContext } from "../../../contexts/UserSettingsContext";
import { DateTime } from "luxon";
import { getEndDayOfMonth, getStartDayOfMonth, nowLocal } from "../../../helpers/DateTimeService";
import MonthNavigation from "../MonthNavigation";
import { Alert, Button, Collapse, FormControlLabel, Switch } from "@mui/material";
import {
  ApiEmployeeOpeningHours,
  EmployeeOpeningHours,
} from "../../../models/api/EmployeeOpeningHoursApi";
import { convertFromApi } from "../../../utils/apiConverters/employeeOpeningHours";
import {
  convertToCreateApiModel,
  convertToUpdateApiModel,
} from "../../../utils/apiConverters/openingHours";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

interface Props {
  employee?: Employee;
  onSave: () => void;
  onCancel: () => void;
}

const EmployeeWorkingHoursForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { state: userSettings } = useContext(UserSettingsContext);
  const { state: openingHoursState, dispatch: openingHoursDispatch } = useContext(
    OpeningHoursContext,
  );

  if (!props.employee) {
    throw new Error("Employee must be set when showing the form.");
  }

  const [fromDate, setFromDate] = useState<DateTime>(
    getStartDayOfMonth(nowLocal().year, nowLocal().month),
  );
  const [toDate, setToDate] = useState<DateTime>(
    getEndDayOfMonth(nowLocal().year, nowLocal().month),
  );
  const [hasOwnRegularOpeningTimes, setHasOwnRegularOpeningTimes] = useState<boolean>(false);

  const url = urls.api.workingHoursOfEmployee(props.employee.id, fromDate, toDate);
  const [{ data: workingHours, loading: isLoadingWorkingHours }, reloadWorkingHours] = useAxios<
    EmployeeOpeningHours | undefined
  >({
    url: url,
    transformResponse: (response) => {
      return convertFromApi(parseJson<ApiEmployeeOpeningHours>(response));
    },
  });

  const [{ loading: isSavingNewSpecialDays }, saveNewSpecialDays] = useAxios(
    {
      url: urls.api.openingTimes,
      method: "POST",
    },
    { manual: true },
  );
  const [{ loading: isUpdatingWorkingHours }, runUpdate] = useAxios(
    {
      method: "PUT",
    },
    { manual: true },
  );

  useEffect(() => {
    reloadWorkingHours({ url: url });
  }, [props.employee, fromDate, toDate, reloadWorkingHours, url]);

  const save = async () => {
    if (!userSettings.branch) {
      throw new Error("Branch must be set when saving working hours.");
    }

    if (!props.employee) {
      throw new Error("Employee must be set when saving his working hours.");
    }

    try {
      await runUpdate({
        url: urls.api.openingTimes,
        data: convertToUpdateApiModel(
          userSettings.branch.id,
          props.employee.id,
          hasOwnRegularOpeningTimes,
          openingHoursState,
        ),
      });

      enqueueSnackbar(t("components.openingHours.standardOpeningHoursSavedSuccessfully"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("components.openingHours.standardOpeningHoursSavingFailed"), {
        variant: "error",
      });
    }

    try {
      await saveNewSpecialDays({
        data: convertToCreateApiModel(
          userSettings.branch.id,
          props.employee.id,
          openingHoursState.newSpecialDays,
        ),
      });

      enqueueSnackbar(t("components.openingHours.exceptionalOpeningHoursSavedSuccessfully"), {
        variant: "success",
      });

      props.onSave();
    } catch {
      enqueueSnackbar(t("components.openingHours.exceptionalOpeningHoursSavingFailed"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    openingHoursDispatch({ type: SET_WEEKDAYS, value: workingHours?.weekdays });
    openingHoursDispatch({
      type: SET_EXISTING_SPECIAL_DAYS,
      value: workingHours?.existingSpecialDays ?? [],
    });

    if (workingHours) {
      setHasOwnRegularOpeningTimes(workingHours.hasOwnRegularOpeningHours);
    }
  }, [openingHoursDispatch, workingHours]);

  const [mode] = useState<"regular-working-hours" | "separate-working-hours">(
    "regular-working-hours",
  );

  const selectPreviousMonth = () => {
    const nextMonth = fromDate.minus({ months: 1 });

    setFromDate(getStartDayOfMonth(nextMonth.year, nextMonth.month));
    setToDate(getEndDayOfMonth(nextMonth.year, nextMonth.month));
  };

  const selectNextMonth = () => {
    const nextMonth = fromDate.plus({ months: 1 });

    setFromDate(getStartDayOfMonth(nextMonth.year, nextMonth.month));
    setToDate(getEndDayOfMonth(nextMonth.year, nextMonth.month));
  };

  return (
    <>
      <div className={"d-flex align-items-center mb-2"}>
        <h4 className={"mb-0"}>
          {mode === "regular-working-hours"
            ? t("components.openingHours.regularWorkingHours")
            : t("components.openingHours.workDaysCalendar")}
        </h4>

        <FormControlLabel
          control={
            <Switch
              checked={
                hasOwnRegularOpeningTimes && userSettings.branch?.isIndividualWorkScheduleAllowed
              }
              onChange={() => setHasOwnRegularOpeningTimes((_) => !_)}
              disabled={!userSettings.branch?.isIndividualWorkScheduleAllowed}
            />
          }
          label={t("components.openingHours.iHaveOwnWorkingHours") as string}
          sx={{ ml: 2, mb: 0 }}
        />
      </div>

      {mode === "regular-working-hours" && (
        <>
          {isLoadingWorkingHours && <Preloader />}

          {!userSettings.branch?.isIndividualWorkScheduleAllowed && (
            <Alert severity={"info"}>
              {t("pages.employees.yourBranchDoesNotAllowYouToSetYourOwnOpeningTime")}
            </Alert>
          )}

          <Collapse in={!hasOwnRegularOpeningTimes}>
            <Alert severity={"info"}>
              {t("pages.employees.yourWorkTimeIsTheSameLikeOpeningTimeOfBranch")}
            </Alert>
          </Collapse>

          <Collapse
            in={hasOwnRegularOpeningTimes && userSettings.branch?.isIndividualWorkScheduleAllowed}
          >
            <StandardOpeningHours
              openLabel={t("components.openingHours.iWork")}
              closedLabel={t("components.openingHours.iDoNotWork")}
              openFromLabel={t("components.openingHours.start")}
              openToLabel={t("components.openingHours.end")}
            />
          </Collapse>

          <hr className={"my-3"} />

          <div className={"d-flex align-items-center justify-content-between"}>
            <h4>{t("components.openingHours.specialWorkingDays")}</h4>
            <MonthNavigation
              dayInCurrentMonth={fromDate}
              selectPreviousMonth={selectPreviousMonth}
              selectNextMonth={selectNextMonth}
            />
          </div>
          {isLoadingWorkingHours && <Preloader />}

          {workingHours && (
            <ExceptionalOpeningHours
              relatedTo={"employee"}
              employeeId={props.employee.id}
              openLabel={t("components.openingHours.iWork")}
              closedLabel={t("components.openingHours.iDoNotWork")}
              openFromLabel={t("components.openingHours.start")}
              openToLabel={t("components.openingHours.end")}
              addNewLabel={t("components.openingHours.addException")}
            />
          )}
        </>
      )}

      {mode === "separate-working-hours" && <OpeningHoursCalendarForm />}

      <div className="d-flex justify-content-start align-items-center mt-5">
        <LoadingButton
          color={"primary"}
          variant={"contained"}
          onClick={save}
          className={"mr-2"}
          loading={isUpdatingWorkingHours || isSavingNewSpecialDays}
        >
          {t("common.save")}
        </LoadingButton>
        <Button variant={"outlined"} onClick={props.onCancel}>
          {t("common.cancel")}
        </Button>
      </div>
    </>
  );
};

export default EmployeeWorkingHoursForm;
