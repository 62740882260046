import React, { FC, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../../common/inputs/TextInput";
import CheckboxInput from "../../common/inputs/CheckboxInput";
import { apiMessageToi18nKey, mailPattern } from "../../helpers/utils";
import { urls } from "../../helpers/urls";
import { Link } from "react-router-dom";
import registerSvg from "../../img/svgs/register.svg";
import { useAuthContext } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginLayout from "../../components/layouts/LoginLayout";
import { PASSWORD_MIN_LENGTH, PASSWORD_VALIDATION_REGEX } from "../../utils/constants";
import RegistrationInfo from "../../components/registration/RegistrationInfo";
import { useSnackbar } from "notistack";

interface FormData {
  email: string;
  password: string;
  repeatedPassword: string;
  hasAcceptedConditions: boolean;
}

const RegisterPage: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isRegistered, setIsRegistered] = useState(false);

  const { signUp } = useAuthContext();
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    mode: "onBlur",
  });

  const onSubmit = (data: FormData) =>
    signUp(data.email, data.password).then(
      () => {
        setIsRegistered(true);
      },
      (error) => {
        enqueueSnackbar(t(apiMessageToi18nKey(error.response.data.message)), { variant: "error" });
      },
    );

  if (isRegistered) {
    return (
      <div className="container-fluid py-2">
        <div className="row vh-100">
          <div className="col-12 col-md-4 offset-md-4 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className={"my-2"}>{t("pages.register.registrationSuccessful")}</h2>
              </Card.Header>
              <Card.Body className={"text-center"}>
                <div className={"d-flex align-items-center"}>
                  <FontAwesomeIcon icon={"check"} className={"text-success text-xl ml-2 mr-4"} />
                  <div className={"d-flex flex-grow-1 text-left"}>
                    {t("pages.register.weSentYouConfirmationEmailPleaseCheckYourInbox")}
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className={"text-center"}>
                <Link to={urls.login} className={"text-sm"}>
                  {t("common.login")}
                </Link>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  return (
    <LoginLayout>
      <div className="container-fluid">
        <div className="row">
          <div
            className={"col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center"}
          >
            <img src={registerSvg} alt={t("pages.register.register")} className={"w-100"} />
          </div>
          <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className="my-2">{t("pages.register.registration")}</h2>
              </Card.Header>
              <Card.Body>
                <RegistrationInfo />

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <TextInput
                      ref={register({
                        required: t<string>("pages.register.pleaseEnterValidEmail"),
                        pattern: {
                          value: mailPattern,
                          message: t("pages.register.pleaseEnterValidEmail"),
                        },
                      })}
                      id="email"
                      name="email"
                      label={t("common.email")}
                      type="text"
                      error={errors.email?.message}
                    />
                    <TextInput
                      ref={register({
                        required: t<string>("pages.register.pleaseEnterYourPassword"),
                        minLength: {
                          value: PASSWORD_MIN_LENGTH,
                          message: t("pages.register.passwordMinLength", {
                            minLength: PASSWORD_MIN_LENGTH,
                          }),
                        },
                        pattern: {
                          value: PASSWORD_VALIDATION_REGEX,
                          message: t("pages.register.passwordRequiredChars"),
                        },
                      })}
                      id="password"
                      name="password"
                      label={t("common.password")}
                      type="password"
                      error={errors.password?.message}
                    />
                    <TextInput
                      ref={register({
                        required: t<string>("pages.register.pleaseRepeatYourPasswordOnceAgain"),
                        validate: (value) =>
                          value === watch("password") ||
                          t<string>("pages.register.passwordsShouldMatch"),
                      })}
                      id="password"
                      name="repeatedPassword"
                      label={t("pages.register.passwordOnceAgain")}
                      type="password"
                      error={errors.repeatedPassword?.message}
                    />
                    <CheckboxInput
                      ref={register({
                        required: t<string>(
                          "pages.register.whenRegisteringYouMustAgreeWithTermsAndConditions",
                        ),
                      })}
                      id="acceptTerms"
                      name="hasAcceptedConditions"
                      label={t("pages.register.iAgreeWithTermsAndConditions")}
                      error={errors.hasAcceptedConditions?.message}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button variant="dark" type="submit" disabled={isSubmitting}>
                      {t("pages.register.register")}
                    </Button>
                  </div>
                </form>
              </Card.Body>
              <Card.Footer className={"text-center"}>
                <Link to={urls.login} className={"text-sm"}>
                  {t("pages.register.alreadyHaveAccountLogIn")}
                </Link>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default RegisterPage;
