import React, { FC, useContext, useState } from "react";
import OpeningHoursSingle, { OpeningHoursChangeHandlers } from "./OpeningHoursSingle";
import {
  allWeekdays,
  OpeningHoursContext,
  OpeningHoursWeekDay,
  OpeningHoursWithPause,
  WeekDays,
} from "../../contexts/OpeningHoursContext";
import {
  SET_WEEKDAY_END_TIME,
  SET_WEEKDAY_PAUSE_END_TIME,
  SET_WEEKDAY_PAUSE_START_TIME,
  SET_WEEKDAY_START_TIME,
} from "../../contexts/OpeningHoursReducer";
import { useTranslation } from "react-i18next";

interface Props {
  openFromLabel?: string;
  openToLabel?: string;
  openLabel?: string;
  closedLabel?: string;
}

const StandardOpeningHours: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { state: openingHoursState, dispatch: openingHoursDispatch } = useContext(
    OpeningHoursContext,
  );

  const [copiedHours, setCopiedHours] = useState<OpeningHoursWithPause>();

  const getOpeningHoursForDay = (day: WeekDays): OpeningHoursWeekDay | undefined => {
    return openingHoursState.weekdays[day];
  };

  const getHandlers = (day: WeekDays): OpeningHoursChangeHandlers => {
    return {
      onStartTimeChange: (_) =>
        openingHoursDispatch({ type: SET_WEEKDAY_START_TIME, value: { day: day, time: _ } }),
      onEndTimeChange: (_) =>
        openingHoursDispatch({ type: SET_WEEKDAY_END_TIME, value: { day: day, time: _ } }),
      onPauseStartTimeChange: (_) =>
        openingHoursDispatch({ type: SET_WEEKDAY_PAUSE_START_TIME, value: { day: day, time: _ } }),
      onPauseEndTimeChange: (_) =>
        openingHoursDispatch({ type: SET_WEEKDAY_PAUSE_END_TIME, value: { day: day, time: _ } }),
    };
  };

  const labels = {
    openLabel: props.openLabel,
    closedLabel: props.closedLabel,
  };

  return (
    <table className={"table table-borderless table-responsive table-sm"}>
      <colgroup>
        <col style={{ width: "6em" }} />
        <col style={{ width: "8em" }} />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <tbody>
        <tr>
          <td />
          <td />
          <td className={"text-center text-uppercase"}>
            <span className={"badge"}>{props.openFromLabel ?? "Otvorené od"}</span>
          </td>
          <td className={"text-center text-uppercase"}>
            <span className={"badge"}>{props.openToLabel ?? "Otvorené do"}</span>
          </td>
          <td />
        </tr>

        {allWeekdays.map((_) => (
          <OpeningHoursSingle
            key={_}
            dayName={t(`common.days.${_.toLowerCase()}`)}
            listId={_}
            copyHours={(_) => setCopiedHours(_)}
            copiedHours={copiedHours}
            {...getOpeningHoursForDay(_)}
            {...getHandlers(_)}
            {...labels}
          />
        ))}
      </tbody>
    </table>
  );
};

export default StandardOpeningHours;
