import React, { FC, useState } from "react";
import OpeningHoursSingle from "../OpeningHoursSingle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { OpeningHoursWithPause } from "../../../contexts/OpeningHoursContext";
import { DateTime } from "luxon";
import { isSameDay } from "../../../helpers/DateTimeService";

interface Props {}

const defaultStartDate = DateTime.utc();
const OpeningHoursCalendarForm: FC<Props> = (props) => {
  const [filledCalendarDays, setFilledCalendarDays] = useState<
    (OpeningHoursWithPause & { date: DateTime })[]
  >([]);

  const [startDate, setStartDate] = useState<DateTime>(defaultStartDate);
  const [endDate, setEndDate] = useState<DateTime>(defaultStartDate.plus({ days: 7 }));

  const getAllDates = (): DateTime[] => {
    let dates: DateTime[] = [];

    for (let i = startDate; i < endDate; i = i.plus({ days: 1 })) {
      dates.push(i);
    }

    return dates;
  };

  const setPreviousWeek = () => {
    setStartDate((_) => _.plus({ days: -7 }));
    setEndDate((_) => _.plus({ days: -7 }));
  };

  const setNextWeek = () => {
    setStartDate((_) => _.plus({ days: 7 }));
    setEndDate((_) => _.plus({ days: 7 }));
  };

  const getOpeningHoursByDate = (date: DateTime): OpeningHoursWithPause | undefined => {
    const filteredDateIndex = filledCalendarDays.findIndex((_) => isSameDay(_.date, date));
    if (filteredDateIndex === -1) {
      return undefined;
    }

    return filledCalendarDays[filteredDateIndex];
  };

  const onChange = (
    date: DateTime,
    time: DateTime | undefined,
    whatToChange: keyof OpeningHoursWithPause,
  ) => {
    if (time) {
      time = time.set({ second: 0 });
    }

    const filteredDateIndex = filledCalendarDays.findIndex((_) => isSameDay(_.date, date));
    if (filteredDateIndex === -1) {
      setFilledCalendarDays((_) => {
        let newArray = [..._];
        let newItem: OpeningHoursWithPause & { date: DateTime } = {
          date: date,
        };
        newItem[whatToChange] = time;

        newArray = newArray.concat(newItem);

        return newArray;
      });
    } else {
      setFilledCalendarDays((_) => {
        let newArray = [..._];
        let itemToChange = newArray[filteredDateIndex];
        itemToChange[whatToChange] = time;

        return newArray;
      });
    }
  };

  return (
    <>
      <table className={"table table-borderless table-responsive table-sm mt-4 mb-1"}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <button
                type="button"
                className="btn btn-transparent-dark btn-sm d-flex align-items-center ml-n2"
                onClick={setPreviousWeek}
              >
                <FontAwesomeIcon icon={faChevronUp} className={"d-flex mr-2"} />
                <span>Predošlý týždeň</span>
              </button>
            </td>
            <td className={"text-center text-uppercase"}>
              <span className={"badge"}>{"Začiatok"}</span>
            </td>
            <td className={"text-center text-uppercase"}>
              <span className={"badge"}>{"Koniec"}</span>
            </td>
            <td />
          </tr>
          {getAllDates().map((_) => {
            const openingHours = getOpeningHoursByDate(_);
            return (
              <OpeningHoursSingle
                key={_.toString()}
                dayName={_.toFormat("dd. MM. yyyy, ccc")}
                listId={_.toString()}
                startTime={openingHours?.startTime}
                endTime={openingHours?.endTime}
                pauseStartTime={openingHours?.pauseStartTime}
                pauseEndTime={openingHours?.pauseEndTime}
                onStartTimeChange={(time) => onChange(_, time, "startTime")}
                onEndTimeChange={(time) => onChange(_, time, "endTime")}
                onPauseStartTimeChange={(time) => onChange(_, time, "pauseStartTime")}
                onPauseEndTimeChange={(time) => onChange(_, time, "pauseEndTime")}
                openLabel={"Pracujem"}
                closedLabel={"Nepracujem"}
              />
            );
          })}
        </tbody>
      </table>

      <button
        type="button"
        className="btn btn-transparent-dark btn-sm d-flex align-items-center ml-n1"
        onClick={setNextWeek}
      >
        <FontAwesomeIcon icon={faChevronDown} className={"d-flex mr-2"} />
        <span className={"d-flex"}>Ďalší týždeň</span>
      </button>
    </>
  );
};

export default OpeningHoursCalendarForm;
