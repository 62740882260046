import useAxios, { RefetchOptions } from "axios-hooks";
import { apiUrls } from "../../helpers/urls";
import { User } from "../../models/User";
import { AxiosError, AxiosPromise, AxiosRequestConfig } from "axios";

interface UserApiHook {
  user: User | undefined;
  isLoading: boolean;
  error: AxiosError | undefined;
  fetch: (
    config?: AxiosRequestConfig | undefined,
    options?: RefetchOptions | undefined,
  ) => AxiosPromise<User>;
}

export const useUserApi = (): UserApiHook => {
  const [{ data, loading, error }, fetchUser] = useAxios<User>(
    { url: apiUrls.user },
    { manual: true, useCache: false },
  );

  return {
    user: data,
    isLoading: loading,
    error,
    fetch: fetchUser,
  };
};
