import React, { FC, ReactElement } from "react";
import Footer from "../common/Footer";
import Header from "../common/header/Header";
import AppBar from "../common/header/AppBar";

interface Props {
  title: string;
  subtitle?: string;
  toolbar?: ReactElement;
}

const AdminLayout: FC<Props> = (props) => {
  return (
    <>
      <AppBar />
      <Header title={props.title} subtitle={props.subtitle} toolbar={props.toolbar} />
      <div className={"container mt-n10 pb-5"}>{props.children}</div>
      <Footer />
    </>
  );
};

export default AdminLayout;
