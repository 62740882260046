import React, { FC, useState } from "react";
import { apiBaseUrl, apiUrls } from "../../helpers/urls";
import { Box, CircularProgress, Collapse, SxProps, Theme } from "@mui/material";

interface Props {
  branchId: number;
  branchName: string;
  sx?: SxProps<Theme>;
}

const BranchLogo: FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onLoad = () => {
    setIsVisible(true);
    setIsLoading(false);
  };

  const onError = () => {
    setIsVisible(false);
    setIsLoading(false);
  };

  const url = `${apiBaseUrl}/${apiUrls.logoOfBranchNoCache(props.branchId)}`;

  return (
    <>
      <Collapse in={isLoading} orientation={"horizontal"}>
        <CircularProgress />
      </Collapse>

      <Collapse in={isVisible} orientation={"horizontal"}>
        <Box sx={props.sx}>
          <img
            src={url}
            onLoad={onLoad}
            onError={onError}
            alt={props.branchName}
            style={{ maxWidth: "5em" }}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default BranchLogo;
