import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import ButtonWithConfirmAction from "../common/ButtonWithConfirmAction";
import { useTranslation } from "react-i18next";

interface Props {
  deleteBranch: () => void;
}

const BranchOtherSettings: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ px: 1, py: 2 }}
    >
      <Stack direction={"column"}>
        <Typography variant={"body1"}>{t("pages.branches.deleteBranch")}</Typography>
        <Typography variant={"body2"}>
          {t("pages.branches.allReservationsEmployeesAndServicesWillBeDeleted")}
        </Typography>
      </Stack>
      <ButtonWithConfirmAction
        color={"error"}
        confirm={{
          confirmButtonText: t("common.delete"),
          confirmButtonProps: { color: "error" },
          declineButtonText: t("common.cancel"),
          description: t("pages.branches.allReservationsEmployeesAndServicesWillBeDeleted"),
          onClick: props.deleteBranch,
        }}
      >
        {t("common.delete")}
      </ButtonWithConfirmAction>
    </Stack>
  );
};

export default BranchOtherSettings;
