import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginLayout from "../../components/layouts/LoginLayout";
import accountConfirmationSvg from "../../img/svgs/account-confirmation.svg";
import accountConfirmationFailedSvg from "../../img/svgs/account-confirmation-fail.svg";
import { Card } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { urls } from "../../helpers/urls";
import useAxios from "axios-hooks";
import { Alert, Button, Fade } from "@mui/material";

interface Props {}

const AccountConfirmationPage: FC<Props> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const search = useLocation().search;

  const [{ loading, error }, confirmAccount] = useAxios({}, { manual: true });
  const [confirmationStarted, setConfirmationStarted] = useState<boolean>(false);

  const runConfirmation = useCallback(
    async (url: string) => {
      setConfirmationStarted(true);
      await confirmAccount({ url });
    },
    [confirmAccount],
  );

  const goToLogin = () => {
    history.push(urls.login);
  };

  const goToRegister = () => {
    history.push(urls.registerForm);
  };

  useEffect(() => {
    const userId = new URLSearchParams(search).get("userId") ?? "";
    const code = new URLSearchParams(search).get("code") ?? "";

    runConfirmation(urls.api.confirmAccount(userId, code));
  }, [runConfirmation, search]);

  const isAccountConfirmed = confirmationStarted && !loading && !error;
  const isAccountConfirmationFailed = confirmationStarted && !loading && !!error;

  return (
    <LoginLayout>
      <div className="container-fluid py-2">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center">
            {isAccountConfirmed && (
              <Fade in={isAccountConfirmed}>
                <img
                  src={accountConfirmationSvg}
                  alt={t("pages.register.accountVerification")}
                  className="w-100"
                />
              </Fade>
            )}

            {isAccountConfirmationFailed && (
              <Fade in={isAccountConfirmationFailed}>
                <img
                  src={accountConfirmationFailedSvg}
                  alt={t("pages.register.accountVerification")}
                  className="w-100"
                />
              </Fade>
            )}
          </div>
          <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className="my-2">{t("pages.register.accountVerification")}</h2>
              </Card.Header>
              <Card.Body>
                {!confirmationStarted ||
                  (loading && t("pages.register.accountVerificationInProgress"))}

                {isAccountConfirmed && (
                  <>
                    <Alert severity={"success"} sx={{ mb: 3 }}>
                      {t("pages.register.yourAccountWasConfirmedYouCanLogIn")}
                    </Alert>

                    <Button variant={"contained"} onClick={goToLogin}>
                      {t("common.login")}
                    </Button>
                  </>
                )}

                {isAccountConfirmationFailed && (
                  <>
                    <Alert severity={"error"} sx={{ mb: 3 }}>
                      {t("pages.register.accountVerificationFailedPleaseTryAgain")}
                    </Alert>

                    <Button variant={"contained"} onClick={goToRegister}>
                      {t("pages.register.register")}
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default AccountConfirmationPage;
