import React, { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Employee } from "../../models/Employee";
import { Tooltip } from "@mui/material";

interface Props {
  employees: Employee[];
  editWorkingHours: (employee: Employee) => void;
  onCreate: () => void;
  onEdit: (employee: Employee) => void;
  onDelete: (employee: Employee) => void;
}

const EmployeesList: FC<Props> = ({ onEdit, onDelete, onCreate, employees, editWorkingHours }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="row mb-3">
        {(!employees || employees.length === 0) && (
          <div className="col-md-4">
            <Card>
              <Card.Header>{t("common.nothingFoundHere")} ...</Card.Header>
              <Card.Body>{t("pages.employees.addInfo")}</Card.Body>
            </Card>
          </div>
        )}
        {employees?.map((_) => (
          <div className="col-md-4 mb-4" key={_.id}>
            <Card className={"h-100"}>
              <Card.Header className="d-flex justify-content-between align-items-center h-100">
                <div>
                  {_.firstName} {_.lastName}
                </div>
                <div>
                  <Tooltip title={"Upraviť pracovnú dobu"}>
                    <Button
                      size={"sm"}
                      variant={"dark"}
                      className="mr-2 my-1 py-2"
                      onClick={() => editWorkingHours(_)}
                    >
                      <FontAwesomeIcon icon={"clock"} />
                    </Button>
                  </Tooltip>

                  <Button
                    size={"sm"}
                    variant={"dark"}
                    className="mr-2 my-1"
                    onClick={() => onEdit(_)}
                  >
                    {t("common.edit")}
                  </Button>
                  <Button
                    size={"sm"}
                    variant={"dark"}
                    className={"my-1"}
                    onClick={() => onDelete(_)}
                  >
                    {t("common.delete")}
                  </Button>
                </div>
              </Card.Header>
            </Card>
          </div>
        ))}
        <div className="align-items-center col-md-4 d-flex justify-content-center">
          <Button
            size={"lg"}
            variant={"light"}
            className="px-5 text-uppercase-expanded font-weight-500 shadow"
            onClick={() => onCreate()}
          >
            <FontAwesomeIcon icon={"plus"} className={"mr-3"} />
            {t("common.add")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EmployeesList;
