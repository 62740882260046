import React, { FC } from "react";
import { Branch } from "../../models/Branch";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

interface Props {
  branches: Branch[];
  editOpeningHours: (branch: Branch) => void;
  onCreate: () => void;
  onEdit: (branch: Branch) => void;
}

const BranchList: FC<Props> = (props) => {
  const { t } = useTranslation();

  const editOpeningHours = (branch: Branch): void => {
    props.editOpeningHours(branch);
  };

  return (
    <>
      <div className="row mb-3">
        {props.branches?.length > 0 ? null : (
          <div className="col-md-4">
            <Card>
              <Card.Header>{t("common.nothingFoundHere")} ...</Card.Header>
              <Card.Body>{t("pages.branches.addInfo")}</Card.Body>
            </Card>
          </div>
        )}

        {props.branches?.map((_) => (
          <div className="col-md-4 mb-4" key={_.id}>
            <Card className={"h-100"}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div className={"d-flex mr-2"}>
                  {_.name ?? <i>{t("pages.branches.branchWithoutName")}</i>}
                </div>
                <div className={"d-flex flex-grow-1 justify-content-end"}>
                  <Tooltip title={"Upraviť otváracie hodiny"}>
                    <Button
                      size={"sm"}
                      variant={"dark"}
                      className="mr-2 py-2"
                      onClick={() => editOpeningHours(_)}
                    >
                      <FontAwesomeIcon icon={"clock"} />
                    </Button>
                  </Tooltip>

                  <Button size={"sm"} variant={"dark"} onClick={() => props.onEdit(_)}>
                    {t("common.edit")}
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <b>{t("common.email")}:</b> {_.email}
                <br />
                <b>{t("pages.branches.address")}:</b> {_.address}
                <br />
                <b>{t("common.phone")}:</b> {_.phoneNumber}
              </Card.Body>
            </Card>
          </div>
        ))}
        <div className="align-items-center col-md-4 d-flex justify-content-center p-5">
          <Button
            size={"lg"}
            variant={"light"}
            className="px-5 text-uppercase-expanded font-weight-500 shadow"
            onClick={() => props.onCreate()}
          >
            <FontAwesomeIcon icon={"plus"} className={"mr-3"} />
            {t("common.add")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BranchList;
