import React, { FC, MouseEvent, useState } from "react";
import { MenuItemWithSubmenu } from "./AppBar";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MenuDropdown: FC<MenuItemWithSubmenu> = (props) => {
  const history = useHistory();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const redirectTo = (path: string) => {
    history.push(path);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Button
        onClick={handleOpenUserMenu}
        variant={"text"}
        endIcon={<ExpandMoreIcon />}
        sx={{ mx: 1, px: 1 }}
      >
        {props.title}
      </Button>

      <Menu
        sx={{ mt: "45px" }}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElement)}
        onClose={handleCloseUserMenu}
        PaperProps={{ sx: { p: 1 } }}
      >
        {props.subItems.map((_) => (
          <MenuItem key={_.title} onClick={() => redirectTo(_.url)}>
            <Typography textAlign="center">{_.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuDropdown;
