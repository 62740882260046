import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import { CookiesProvider } from "react-cookie";
import { AuthStateProvider } from "./contexts/AuthContext";
import AppRoutes from "./AppRoutes";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarMinus,
  faCalendarPlus,
  faCheck,
  faClock,
  faCopy,
  faMinus,
  faPaste,
  faPlus,
  faTimes,
  faUser,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import "./App.scss";
import { ReservationFormStateProvider } from "./components/reservations/ReservationFormStateProvider";
import { OpeningHoursContextProvider } from "./contexts/OpeningHoursContext";
import { UserSettingsContextProvider } from "./contexts/UserSettingsContext";
import useAxios from "axios-hooks";
import axiosInstance from "./axios";
import { Settings } from "luxon";
import { appTimeZone } from "./helpers/DateTimeService";
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./config/muiTheme";
import { SnackbarProvider } from "notistack";

library.add(
  faCheck,
  faPlus,
  faUser,
  faWrench,
  faTimes,
  faClock,
  faMinus,
  faCalendarPlus,
  faCalendarMinus,
  faCopy,
  faPaste,
);

useAxios.configure({ axios: axiosInstance });
Settings.defaultLocale = "sk";
Settings.defaultZone = appTimeZone;

export default function App() {
  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <CookiesProvider>
          <ThemeProvider theme={muiTheme}>
            <SnackbarProvider maxSnack={3}>
              <ReservationFormStateProvider>
                <OpeningHoursContextProvider>
                  <AuthStateProvider>
                    <UserSettingsContextProvider>
                      <Router>
                        <AppRoutes />
                      </Router>
                    </UserSettingsContextProvider>
                  </AuthStateProvider>
                </OpeningHoursContextProvider>
              </ReservationFormStateProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </CookiesProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
}
