import useAxios from "axios-hooks";
import { Employee } from "../../models/Employee";
import { urls } from "../../helpers/urls";
import { useEffect } from "react";

interface HookResult {
  loading: boolean;
  error: any;
  data: Employee[];
  reload: () => void;
}

export const useEmployeesApi = (branchId: number | undefined): HookResult => {
  const [{ data: employees, loading: isLoadingEmployees, error }, reloadEmployeesAxios] = useAxios<
    Employee[]
  >({ url: branchId ? urls.api.employeesOfBranch(branchId) : "" }, { manual: true });

  useEffect(() => {
    if (!branchId) {
      return;
    }

    reloadEmployeesAxios();
  }, [branchId, reloadEmployeesAxios]);

  return {
    data: employees ?? [],
    loading: isLoadingEmployees,
    error: error,
    reload: reloadEmployeesAxios,
  };
};
