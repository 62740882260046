import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import "./OverlayContainer.scss";

interface Props {
  title: string;
  onClose?: () => void;
  variant?: "standard" | "wide";
}

const OverlayContainer: React.FC<Props> = ({ children, title, onClose, variant = "standard" }) => {
  return (
    <div className="overlay-container">
      <div className="container-fluid h-100">
        <div className="row h-100" onClick={onClose}>
          <div
            onClick={(_) => _.stopPropagation()}
            className={cx("p-5 bg-light shadow-left", {
              "col-md-6 offset-md-6": variant === "standard",
              "col-md-9 offset-md-3": variant === "wide",
            })}
          >
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="d-flex mb-0">{title}</h2>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-transparent-dark btn-icon"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={"times"} />
                </button>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayContainer;
