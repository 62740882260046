import React, { FC, MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import Box from "@mui/material/Box";
import { Button, Menu, MenuItem, Skeleton, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Employee } from "../../models/Employee";
import { useEmployeesApi } from "../../hooks/api/useEmployeesApi";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onEmployeeSelect: (employee: Employee | undefined) => void;
}

const SelectEmployee: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { state: userSettings } = useContext(UserSettingsContext);

  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const { data: employees, loading: isLoadingEmployees } = useEmployeesApi(userSettings.branch?.id);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleOpenBranchSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseBranchSelect = () => {
    setAnchorElement(null);
  };

  const selectEmployee = (employee: Employee | undefined) => {
    setSelectedEmployee(employee);
    props.onEmployeeSelect(employee);
    handleCloseBranchSelect();
  };

  if (isLoadingEmployees) {
    return (
      <Skeleton variant="rectangular">
        <Button size={"small"} />
      </Skeleton>
    );
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Button
        onClick={handleOpenBranchSelect}
        color={"light"}
        sx={{ boxShadow: "none", mx: 0.5 }}
        endIcon={<ExpandMoreIcon />}
      >
        {selectedEmployee
          ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
          : t("components.selectEmployee.selectEmployee")}
      </Button>

      <Menu
        sx={{ mt: "45px", p: 0 }}
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElement)}
        onClose={handleCloseBranchSelect}
        PaperProps={{ sx: { p: 1 } }}
      >
        {selectedEmployee && (
          <MenuItem onClick={() => selectEmployee(undefined)}>
            <Typography textAlign="center" sx={{ color: palette.grey.A700 }}>
              <CloseIcon sx={{ mr: 1 }} />
              {t("components.selectEmployee.cancelFilter")}
            </Typography>
          </MenuItem>
        )}

        {employees.map((_) => (
          <MenuItem key={_.id} onClick={() => selectEmployee(_)}>
            <Typography textAlign="center">
              {_.firstName} {_.lastName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default SelectEmployee;
