import React, { FC, ReactElement, RefObject, useEffect, useState } from "react";
import { Reservation } from "../../models/Reservation";
import { EmployeeReservations } from "../../models/EmployeeReservations";
import "./CalendarEmployeeReservations.scss";
import { Employee } from "../../models/Employee";
import CalendarEmployeeReservation from "./CalendarEmployeeReservation";
import { DateTime } from "luxon";
import CalendarRowFiller from "./CalendarRowFiller";
import { getCalendarRowBlocks } from "../../helpers/calendarHelper";
import { OpeningHoursWithPause } from "../../contexts/OpeningHoursContext";
import { Time } from "../../utils/timeUtils";

interface Props {
  employee: Employee;
  employeeReservations: EmployeeReservations;

  date: DateTime;
  timelineStartTime: Time;
  timelineEndTime: Time;

  branchOpeningHours: OpeningHoursWithPause | undefined;

  addReservation: (employeeId: number, startDateTime: DateTime) => void;
  editReservation: (reservation: Reservation) => void;
  deleteReservation: (reservationId: number) => void;

  hourRemWidth: number;
  tableWrapper: RefObject<HTMLDivElement>;
}

export enum CalendarBlockType {
  Reservation = "reservation",
  EmptyOpen = "empty-open",
  EmptyClosed = "empty-closed",
  Pause = "pause",
}

export interface ReservationCalendarBlock {
  startingAt: DateTime;
  endingAt: DateTime;
  reservation?: Reservation;
  displayRemWidth: number;
  type: CalendarBlockType;
}

const CalendarEmployeeReservations: FC<Props> = (props: Props) => {
  const [reservationCalendarBlocks, setReservationCalendarBlocks] = useState<
    ReservationCalendarBlock[]
  >([]);
  const [notFittingReservations, setNotFittingReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    let reservations = props.employeeReservations.reservations;
    const rowBlocks = getCalendarRowBlocks(
      reservations,
      props.date,
      props.timelineStartTime,
      props.timelineEndTime,
      props.employeeReservations.showOwnOpeningTimes
        ? props.employeeReservations.openingTime
        : props.employeeReservations.openingTime ?? props.branchOpeningHours,
      props.hourRemWidth,
    );

    setReservationCalendarBlocks(rowBlocks.reservationBlocks);
    setNotFittingReservations(rowBlocks.notFittingReservations);
  }, [
    props.employeeReservations,
    props.date,
    props.timelineStartTime,
    props.timelineEndTime,
    props.hourRemWidth,
    props.branchOpeningHours,
  ]);

  const renderCalendarBlock = (block: ReservationCalendarBlock, key: string): ReactElement => {
    switch (block.type) {
      case CalendarBlockType.Reservation:
        return block.reservation ? (
          <CalendarEmployeeReservation
            key={key}
            reservation={block}
            editReservation={props.editReservation}
            deleteReservation={props.deleteReservation}
            tableWrapper={props.tableWrapper}
          />
        ) : (
          <></>
        );
      case CalendarBlockType.EmptyOpen:
        return (
          <CalendarRowFiller
            key={key}
            displayRemWidth={block.displayRemWidth}
            addReservation={() => props.addReservation(props.employee.id, block.startingAt)}
            type={block.type}
            startingAt={block.startingAt}
          />
        );
      case CalendarBlockType.EmptyClosed:
        return (
          <CalendarRowFiller
            key={key}
            displayRemWidth={block.displayRemWidth}
            addReservation={() => {}}
            type={block.type}
            startingAt={block.startingAt}
          />
        );
      case CalendarBlockType.Pause:
        return (
          <CalendarRowFiller
            key={key}
            displayRemWidth={block.displayRemWidth}
            addReservation={() => {}}
            type={block.type}
            startingAt={block.startingAt}
          />
        );
    }
  };

  return (
    <>
      <td className={"py-1 align-middle text-center"}>
        {props.employeeReservations.employee.firstName}{" "}
        {props.employeeReservations.employee.lastName}
        {notFittingReservations.length > 0 && <div className={"ml-1 badge badge-warning"}>!</div>}
      </td>
      <td className={"py-1 align-middle text-center"}>
        <div className={"d-flex"}>
          {reservationCalendarBlocks.map((_, index) => renderCalendarBlock(_, index.toString()))}
        </div>
      </td>
    </>
  );
};
export default CalendarEmployeeReservations;
