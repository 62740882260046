import React, { FC } from "react";
import Footer from "../common/Footer";
import styles from "./LoginLayout.module.scss";
import AppBar from "../common/header/AppBar";

export interface Props {
  withHeader?: boolean;
  withFooter?: boolean;
}

const LoginLayout: FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.withHeader && <AppBar />}
      <div className={"d-flex flex-grow-1 py-4 align-items-center"}>{props.children}</div>
      {props.withFooter && <Footer />}
    </div>
  );
};

export default LoginLayout;

LoginLayout.defaultProps = {
  withHeader: true,
  withFooter: true,
};
