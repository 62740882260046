import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ReservationFormSummary.module.scss";
import { ReservationFormStateContext } from "./ReservationFormStateProvider";

const ReservationFormSummary = () => {
  const { t } = useTranslation();
  const {
    formState: { data },
  } = useContext(ReservationFormStateContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t("summary.title")}</div>
      <div className={styles.grid}>
        <div className={styles.name}>{t("summary.service")}</div>
        <div>{data.reservationTypeId}</div>
        <div className={styles.name}>{t("summary.employee")}</div>
        <div>{data.employeeId}</div>
        <div className={styles.name}>{t("summary.date")}</div>
        <div>{data.date}</div>
        <div className={styles.name}>{t("summary.time")}</div>
        <div>{data.date?.toFormat("HH:mm")}</div>
      </div>
    </div>
  );
};

export default ReservationFormSummary;
