import React, { FC, useContext, useState } from "react";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import EmployeesList from "../components/employees/EmployeesList";
import { Employee } from "../models/Employee";
import OverlayContainer from "../common/OverlayContainer/OverlayContainer";
import EmployeeForm from "../components/employees/EmployeeForm";
import EmployeeWorkingHoursForm from "../components/opening-hours/employee/EmployeeWorkingHoursForm";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { useSnackbar } from "notistack";
import { useEmployeesApi } from "../hooks/api/useEmployeesApi";

const EmployeesPage: FC = () => {
  const { t } = useTranslation();
  const { state: userSettings } = useContext(UserSettingsContext);
  const [form, setForm] = useState<"none" | "new" | "existing" | "working-hours">("none");
  const [formEditingEmployee, setFormEditingEmployee] = useState<Employee>();

  const { enqueueSnackbar } = useSnackbar();

  const { data: employees, loading: isLoadingEmployees, reload: reloadEmployees } = useEmployeesApi(
    userSettings.branch?.id,
  );

  const [, runDeleteEmployee] = useAxios({ method: "DELETE" }, { manual: true });

  const onFormClose = (): void => {
    setForm("none");
    setFormEditingEmployee(undefined);
  };

  return (
    <AdminLayout title={t("components.nav.employees")}>
      {isLoadingEmployees ? (
        <div className="text-center">
          <Spinner animation="border" variant={"dark"} role="status" className="m-5">
            <span className="sr-only">{t("common.loading")}...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <EmployeesList
            employees={employees}
            onCreate={() => setForm("new")}
            onEdit={(employee) => {
              setForm("existing");
              setFormEditingEmployee(employee);
            }}
            editWorkingHours={(employee) => {
              setForm("working-hours");
              setFormEditingEmployee(employee);
            }}
            onDelete={async (employee) => {
              try {
                await runDeleteEmployee({ url: urls.api.employees(employee.id) });
                enqueueSnackbar(t("common.deletedSuccessfully"), { variant: "success" });
                reloadEmployees();
              } catch (e) {
                enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
              }
            }}
          />

          {form !== "none" && (
            <OverlayContainer
              variant={"wide"}
              title={
                form === "new"
                  ? t("pages.employees.addNewEmployee")
                  : t("pages.employees.editEmployee")
              }
              onClose={onFormClose}
            >
              {form !== "working-hours" ? (
                <EmployeeForm
                  employee={formEditingEmployee}
                  onSave={() => {
                    setForm("none");
                    setFormEditingEmployee(undefined);
                    reloadEmployees();
                  }}
                  onCancel={onFormClose}
                />
              ) : (
                <EmployeeWorkingHoursForm
                  employee={formEditingEmployee}
                  onSave={onFormClose}
                  onCancel={onFormClose}
                />
              )}
            </OverlayContainer>
          )}
        </>
      )}
    </AdminLayout>
  );
};

export default EmployeesPage;
