import React, { FC } from "react";
import "./TimePicker.scss";
import { DateTime } from "luxon";
import { default as ExternalTimePicker, TimePickerProps, TimePickerValue } from "react-time-picker";
import { utcToLocal } from "../../../helpers/DateTimeService";
import cx from "classnames";

export type Props = Omit<TimePickerProps, "value" | "onChange"> & {
  value: DateTime | undefined;
  onChange: (value: DateTime) => void;
  className?: string;
  wrapperClassName?: string;
  doNotChangeTimeZones?: boolean;
};

const timeFormat = "HH:mm";

/**
 * Time picker component.
 *
 * Parses the selected time to default app time zone and returns it in time zone of date in props.value
 * @param props
 * @constructor
 */
const TimePicker: FC<Props> = (props) => {
  const onChange = (newValue: TimePickerValue) => {
    if (typeof newValue !== "string") {
      return;
    }

    const originalValue = utcToLocal(props.value ?? DateTime.local());
    let newTime: DateTime;

    const newTimeParsed = DateTime.fromFormat(newValue, timeFormat);
    newTime = originalValue.set({
      hour: newTimeParsed.hour,
      minute: newTimeParsed.minute,
      second: 0,
    });

    if (props.value) {
      newTime = newTime.setZone(props.value.zone);
    }

    props.onChange(newTime);
  };

  const toJSDate = (dateTime: DateTime | undefined): string | undefined => {
    if (!dateTime) {
      return undefined;
    }

    if (props.doNotChangeTimeZones) {
      return dateTime.toFormat(timeFormat);
    }

    return utcToLocal(dateTime).toFormat(timeFormat);
  };

  return (
    <div className={cx("rounded w-100", props.wrapperClassName)}>
      <ExternalTimePicker
        value={toJSDate(props.value) ?? ""}
        onChange={onChange}
        locale={"sk"}
        className={"form-control"}
        disableClock={true}
        clearIcon={null}
      />
    </div>
  );
};

export default TimePicker;
