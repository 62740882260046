import * as React from "react";
import { FC, ReactElement, useState } from "react";
import { AppBar as MuiAppBar, Divider, styled, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { bookinatorUrl, urls } from "../../../helpers/urls";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import { appName } from "../../../helpers/utils";
import logoRobot from "../../../img/svgs/bookinator-logo-robot.min.svg";
import fullLogo from "../../../img/svgs/bookinator-logo.min.svg";
import UserMenu from "./UserMenu";
import SelectBranch from "./SelectBranch";
import MenuDropdown from "./MenuDropdown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { grey } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import LogoutIcon from "@mui/icons-material/Logout";

export interface MenuItem {
  url: string;
  title: string;
  icon?: ReactElement;
  subItems?: never;
}

export interface MenuItemWithSubmenu {
  url: string;
  title: string;
  icon?: ReactElement;
  subItems: MenuItem[];
}

export type MenuItems = (MenuItem | MenuItemWithSubmenu)[];

const drawerWidth = 300;
const getNavItems = (isUserLoggedIn: boolean, t: (translationKey: string) => string): MenuItems =>
  isUserLoggedIn
    ? [
        {
          url: urls.dashboard,
          title: t("components.nav.dashboard"),
          icon: <DashboardIcon />,
        },
        {
          url: urls.calendar,
          title: t("components.nav.calendar"),
          icon: <CalendarMonthIcon />,
        },
        {
          url: "#",
          title: t("components.nav.company"),
          icon: <StoreIcon />,
          subItems: [
            {
              url: urls.companyBranches,
              title: t("components.nav.branches"),
            },
            {
              url: urls.companyReservationTypes,
              title: t("components.nav.services"),
            },
            {
              url: urls.companyEmployees,
              title: t("components.nav.employees"),
            },
            {
              url: urls.settings,
              title: t("components.nav.reservationForms"),
            },
          ],
        },
      ]
    : [];

const StyledAppBar = styled(MuiAppBar)((props) => ({
  backgroundColor: "white",
  position: "relative",
  padding: props.theme.spacing(0.5, 1),
  boxShadow: "0 .15rem 1.75rem 0 rgba(31,45,65,.15)",
}));

const AppBar: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isAtLeastMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { isUserLoggedIn, signOut } = useAuthContext();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const redirectTo = (url: string) => {
    history.push(url);
  };

  const navItems = getNavItems(isUserLoggedIn, t);
  const isFullLogo = isAtLeastMediumScreen || !isUserLoggedIn;

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        {navItems.map((_) => (
          <ListItem
            key={_.title}
            sx={{ px: 0, display: "flex", flexDirection: "column", alignItems: "flex-start" }}
          >
            <ListItemButton onClick={() => redirectTo(_.url)}>
              {_.icon && <Box sx={{ mr: 1, color: theme.palette.primary.main }}>{_.icon}</Box>}
              <ListItemText primary={_.title} />
            </ListItemButton>

            {_.subItems && (
              <List sx={{ ml: 0.7, px: 0 }}>
                {_.subItems.map((__) => (
                  <ListItemButton key={__.title} onClick={() => redirectTo(__.url)}>
                    <KeyboardArrowRightIcon sx={{ color: grey.A700, mr: 1, fontSize: "0.8em" }} />
                    <ListItemText primary={__.title} sx={{ "& span": { fontSize: "0.85em" } }} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </ListItem>
        ))}

        <Divider sx={{ mt: 3, mb: 2 }} />

        <ListItem sx={{ px: 0 }}>
          <ListItemButton onClick={signOut}>
            <LogoutIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            <ListItemText primary={t("components.nav.logout")} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <StyledAppBar>
        <Toolbar>
          {navItems.length > 0 && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            sx={{
              display: "flex",
              flexGrow: { xs: 1, sm: 0 },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href={bookinatorUrl}
              target={"_blank"}
              style={{
                width: "100%",
                maxWidth: isFullLogo ? "8em" : "1.5em",
                marginRight: "1em",
              }}
            >
              <img
                src={isFullLogo ? fullLogo : logoRobot}
                alt={appName}
                className={"logo"}
                style={{ width: "100%", top: "-0.1em", position: "relative" }}
              />
            </a>

            {isUserLoggedIn && isSmallScreen && <SelectBranch />}
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              justifyContent: "space-between",
              alignItems: "center",
              mx: 0.5,
            }}
          >
            <Box>
              {navItems.map((_) => {
                if (_.subItems) {
                  return <MenuDropdown key={_.title} {..._} />;
                }

                return (
                  <Button
                    key={_.title}
                    variant={"text"}
                    onClick={() => redirectTo(_.url)}
                    sx={{ mx: 1, px: 1 }}
                  >
                    {_.title}
                  </Button>
                );
              })}
            </Box>

            {isUserLoggedIn && <SelectBranch />}
          </Box>

          {!isSmallScreen && <UserMenu />}
        </Toolbar>
      </StyledAppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default AppBar;
