import React, { useContext } from "react";
import styles from "./BaseStep.module.scss";
import {
  ReservationFormStateContext,
  setReservationFormStepAction,
} from "../ReservationFormStateProvider";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface BaseStepProps {
  title: string;
  error?: string;
  isSaving?: boolean;
}

const BaseStep: React.FC<BaseStepProps> = ({ title, isSaving, error, children }) => {
  const {
    formState: { step },
    dispatch,
  } = useContext(ReservationFormStateContext);
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h5 className={"mb-3"}>{title}</h5>
      <div>{children}</div>
      <div className={styles.error}>{error}</div>
      <div className={styles.navigation}>
        {step > 1 && (
          <Button
            variant={"outlined"}
            onClick={() => dispatch(setReservationFormStepAction(step - 1))}
            sx={{ mr: 1 }}
          >
            {t("common.previous")}
          </Button>
        )}
        {step < 5 && (
          <LoadingButton variant={"contained"} loading={isSaving} type="submit">
            {step === 4 ? t("common.save") : t("common.next")}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default BaseStep;
