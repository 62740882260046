import React, { FC } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Preloader: FC = () => {
  const { t } = useTranslation();

  return (
    <Spinner animation="border" variant={"dark"} role="status" className="m-5">
      <span className="sr-only">{t("common.loading")}...</span>
    </Spinner>
  );
};

export default Preloader;
