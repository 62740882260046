import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import TextInput from "../common/inputs/TextInput";
import CheckboxInput from "../common/inputs/CheckboxInput";
import { urls } from "../helpers/urls";
import loginSvg from "../img/svgs/login.svg";
import { apiMessageToi18nKey, mailPattern } from "../helpers/utils";
import { useAuthContext } from "../contexts/AuthContext";
import LoginLayout from "../components/layouts/LoginLayout";
import { useSnackbar } from "notistack";

interface LoginForm {
  email: string;
  password: string;
  hasCheckedRememberMe: boolean;
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<LoginForm>();
  const { signIn, isUserLoggedIn } = useAuthContext();

  useEffect(() => {
    if (isUserLoggedIn) {
      history.push(urls.dashboard);
    }
  }, [isUserLoggedIn, history]);

  const onSubmit = async (data: LoginForm) => {
    try {
      await signIn(data.email, data.password, data.hasCheckedRememberMe);
      history.push(urls.dashboard);
    } catch (error) {
      enqueueSnackbar(t(apiMessageToi18nKey(error.response.data?.message)), { variant: "error" });
    }
  };

  return (
    <LoginLayout>
      <div className="container-fluid py-2">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-4 offset-xl-2 d-none d-md-flex align-items-center">
            <img src={loginSvg} alt={t("common.login")} className="w-100" />
          </div>
          <div className="col-12 col-md-6 col-xl-4 d-flex align-items-center">
            <Card className="w-100">
              <Card.Header>
                <h2 className="my-2">{t("common.login")}</h2>
              </Card.Header>
              <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <TextInput
                      ref={register({
                        required: t<string>("pages.login.pleaseEnterYourEmail"),
                        pattern: {
                          value: mailPattern,
                          message: t("pages.register.pleaseEnterValidEmail"),
                        },
                      })}
                      name="email"
                      type="text"
                      id="email"
                      label={t("common.email")}
                      error={errors.email?.message}
                    />
                    <TextInput
                      ref={register({
                        required: t<string>("pages.login.pleaseEnterYourPassword"),
                      })}
                      name="password"
                      id="password"
                      label={t("common.password")}
                      type="password"
                      error={errors.password?.message}
                    />
                    <CheckboxInput
                      id="rememberMe"
                      label={t("pages.login.rememberMe")}
                      ref={register}
                      name={"hasCheckedRememberMe"}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to={urls.forgotPassword} className="text-sm">
                      {t("pages.login.forgotYourPassword")}
                    </Link>
                    <Button variant="dark" type="submit" disabled={isSubmitting}>
                      {t("common.login")}
                    </Button>
                  </div>
                </form>
              </Card.Body>
              <Card.Footer className="text-center">
                <Link to={urls.registerForm} className="text-sm">
                  {t("pages.login.dontHaveAccountYetRegisterNow")}
                </Link>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default LoginPage;
