import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import AdminLayout from "../components/layouts/AdminLayout";
import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { externalUrls } from "../helpers/urls";
import { useSnackbar } from "notistack";

const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    state: { branches },
  } = useContext(UserSettingsContext);

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar(t("pages.settings.urlCopiedToClipboard"), { variant: "success" });
  };

  return (
    <AdminLayout title={t("components.nav.settings")}>
      <Paper>
        <Typography variant={"h2"} gutterBottom>
          {t("pages.settings.customerReservationForm")}
        </Typography>

        {branches.length === 0 && (
          <Alert severity={"info"}>{t("pages.settings.youHaveNoBranchesYet")}</Alert>
        )}

        <List>
          {branches.map((_) => (
            <ListItem key={_.id}>
              <ListItemText>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "start", sm: "center" }}
                  gap={1}
                >
                  <Typography sx={{ flexGrow: 1 }}>
                    {_.name ?? <i>{t("pages.branches.branchWithoutName")}</i>}
                  </Typography>

                  <Stack direction={"row"} sx={{ width: "100%" }}>
                    <TextField
                      label={"URL"}
                      value={externalUrls.publicForm(_.id)}
                      size={"small"}
                      sx={{ mr: 1, flexGrow: 1 }}
                      disabled
                    />

                    <Tooltip title={t<string>("common.copy")}>
                      <IconButton>
                        <ContentCopyIcon
                          onClick={() => copyToClipboard(externalUrls.publicForm(_.id))}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Paper>
    </AdminLayout>
  );
};

export default SettingsPage;
