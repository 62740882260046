import React, { FC, RefObject } from "react";
import { EmployeeReservations } from "../../models/EmployeeReservations";
import CalendarEmployeeReservations from "./CalendarEmployeeReservations";
import { Reservation } from "../../models/Reservation";
import { Employee } from "../../models/Employee";
import { DateTime } from "luxon";
import { OpeningHoursWithPause } from "../../contexts/OpeningHoursContext";
import { Time } from "../../utils/timeUtils";

interface Props {
  date: DateTime;
  timelineStartTime: Time;
  timelineEndTime: Time;

  branchOpeningHours: OpeningHoursWithPause | undefined;

  employeesReservations: EmployeeReservations[];
  allEmployees: Employee[];

  addReservation: (employeeId: number, startDateTime: DateTime) => void;
  editReservation: (reservation: Reservation) => void;
  deleteReservation: (reservationId: number) => void;

  hourRemWidth: number;
  tableWrapper: RefObject<HTMLDivElement>;
}

const CalendarRow: FC<Props> = (props: Props) => {
  const getEmployeeReservations = (employee: Employee): EmployeeReservations => {
    for (let employeeReservations of props.employeesReservations) {
      if (employeeReservations.employee.id === employee.id) {
        return employeeReservations;
      }
    }

    return {
      employee: employee,
      reservations: [],
      showOwnOpeningTimes: false,
    };
  };

  return (
    <>
      {props.allEmployees?.map((_, index) => (
        <tr key={_.id}>
          {index === 0 && (
            <td className="text-center align-middle py-1" rowSpan={props.allEmployees.length}>
              <div
                className={"w-100 border-bottom border-bottom-primary-soft font-weight-700 mb-1"}
              >
                {props.date.toFormat("ccc")}
              </div>
              <span>{props.date.toFormat("d.M.")}</span>
            </td>
          )}

          <CalendarEmployeeReservations
            employee={_}
            employeeReservations={getEmployeeReservations(_)}
            date={props.date}
            timelineStartTime={props.timelineStartTime}
            timelineEndTime={props.timelineEndTime}
            branchOpeningHours={props.branchOpeningHours}
            addReservation={props.addReservation}
            editReservation={props.editReservation}
            deleteReservation={props.deleteReservation}
            hourRemWidth={props.hourRemWidth}
            tableWrapper={props.tableWrapper}
          />
        </tr>
      ))}
    </>
  );
};

export default CalendarRow;
