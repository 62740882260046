import React, { FC } from "react";
import { DateTime } from "luxon";
import { Button, ButtonGroup } from "react-bootstrap";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

interface Props {
  dayInCurrentMonth: DateTime;
  selectPreviousMonth: () => void;
  selectNextMonth: () => void;
}

const MonthNavigation: FC<Props> = (props) => {
  return (
    <ButtonGroup>
      <Button className={"btn-transparent-dark"} size={"sm"} onClick={props.selectPreviousMonth}>
        <FontAwesomeIcon icon={faChevronLeft} className={"mx-1"} />
      </Button>
      <Button className={"btn-transparent-dark"} disabled>
        {props.dayInCurrentMonth.toFormat("LLLL y")}
      </Button>
      <Button className={"btn-transparent-dark"} size={"sm"} onClick={props.selectNextMonth}>
        <FontAwesomeIcon icon={faChevronRight} className={"mx-1"} />
      </Button>
    </ButtonGroup>
  );
};

export default MonthNavigation;
