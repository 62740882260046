import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { urls } from "../helpers/urls";
import { ReservationType } from "../models/ReservationType";
import AdminLayout from "../components/layouts/AdminLayout";
import { useTranslation } from "react-i18next";
import { ReservationTypeList } from "../components/reservation-types/ReservationTypeList";
import { Spinner } from "react-bootstrap";
import OverlayContainer from "../common/OverlayContainer/OverlayContainer";
import ReservationTypeForm from "../components/reservation-types/ReservationTypeForm";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { useSnackbar } from "notistack";

const ReservationTypesPage: FC = () => {
  const { t } = useTranslation();
  const { state: userSettings } = useContext(UserSettingsContext);

  const [form, setForm] = useState<"none" | "new" | "existing">("none");
  const [formEditingReservationType, setFormEditingReservationType] = useState<ReservationType>();

  const { enqueueSnackbar } = useSnackbar();

  const [
    { data: reservationTypes, loading: isLoadingReservationTypes },
    reloadReservationTypesAxios,
  ] = useAxios<ReservationType[]>({}, { manual: true });

  const reloadReservationTypes = useCallback(() => {
    if (!userSettings.branch) {
      return;
    }

    reloadReservationTypesAxios({ url: urls.api.reservationTypesOfBranch(userSettings.branch.id) });
  }, [reloadReservationTypesAxios, userSettings.branch]);

  useEffect(() => {
    reloadReservationTypes();
  }, [reloadReservationTypes, userSettings.branch]);

  const [, runDeleteReservationType] = useAxios({ method: "DELETE" }, { manual: true });

  const onFormClose = (): void => {
    setForm("none");
    setFormEditingReservationType(undefined);
  };

  return (
    <AdminLayout title={t("components.nav.services")}>
      {isLoadingReservationTypes ? (
        <div className="text-center">
          <Spinner animation="border" variant={"dark"} role="status" className="m-5">
            <span className="sr-only">{t("common.loading")}...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <ReservationTypeList
            reservationTypes={reservationTypes}
            onCreate={() => setForm("new")}
            onEdit={(reservationType) => {
              setForm("existing");
              setFormEditingReservationType(reservationType);
            }}
            onDelete={async (reservationType) => {
              try {
                await runDeleteReservationType({
                  url: urls.api.reservationTypes(reservationType.id),
                });
                enqueueSnackbar(t("common.deletedSuccessfully"), { variant: "success" });
                reloadReservationTypes();
              } catch (e) {
                enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
              }
            }}
          />

          {form !== "none" && (
            <OverlayContainer
              title={
                form === "new"
                  ? t("pages.reservationTypes.onCreate")
                  : t("pages.reservationTypes.onEdit")
              }
              onClose={onFormClose}
            >
              <ReservationTypeForm
                reservationType={formEditingReservationType}
                onSave={() => {
                  setForm("none");
                  setFormEditingReservationType(undefined);
                  reloadReservationTypes();
                }}
                onCancel={onFormClose}
              />
            </OverlayContainer>
          )}
        </>
      )}
    </AdminLayout>
  );
};

export default ReservationTypesPage;
