import React, { FC, useContext } from "react";
import { Button } from "react-bootstrap";
import ExceptionalOpeningHoursSingle from "./ExceptionalOpeningHoursSingle";
import {
  getEmptySpecialDay,
  OpeningHoursContext,
  OpeningHoursSpecialDay,
} from "../../contexts/OpeningHoursContext";
import {
  ADD_NEW_SPECIAL_DAY,
  CHANGE_SPECIAL_DAY,
  REMOVE_SPECIAL_DAY,
} from "../../contexts/OpeningHoursReducer";
import useAxios from "axios-hooks";
import { urls } from "../../helpers/urls";
import { UserSettingsContext } from "../../contexts/UserSettingsContext";
import { DateTime } from "luxon";
import { ApiDeleteSpecialDaysOpeningHours } from "../../models/api/OpeningHoursApi";

interface CommonProps {
  addNewLabel?: string;
  openFromLabel?: string;
  openToLabel?: string;
  openLabel?: string;
  closedLabel?: string;
}

interface RelatedToBranchProps {
  relatedTo: "branch";
  employeeId?: never;
}

interface RelatedToEmployeeProps {
  relatedTo: "employee";
  employeeId: number;
}

type Props = CommonProps & (RelatedToBranchProps | RelatedToEmployeeProps);

const ExceptionalOpeningHours: FC<Props> = (props) => {
  const { state: userSettings } = useContext(UserSettingsContext);
  const { state: openingHoursState, dispatch: openingHoursDispatch } = useContext(
    OpeningHoursContext,
  );
  const [, runDelete] = useAxios(
    { url: urls.api.openingTimes, method: "delete" },
    { manual: true },
  );

  const onChange = (
    value: DateTime | undefined,
    idType: "id" | "index",
    id: number,
    whatToChange: keyof Omit<OpeningHoursSpecialDay, "priority">,
  ): void => {
    openingHoursDispatch({
      type: CHANGE_SPECIAL_DAY,
      value: { idType, id, whatToChange, newValue: value },
    });
  };

  const addNewExceptionalOpeningHours = (): void => {
    openingHoursDispatch({ type: ADD_NEW_SPECIAL_DAY, value: getEmptySpecialDay(props.relatedTo) });
  };

  const removeExceptionalOpeningHours = (idType: "id" | "index", id: number): void => {
    if (!userSettings.branch) {
      throw new Error("Branch must be selected when working with opening hours.");
    }

    if (idType === "id") {
      const deleteData: ApiDeleteSpecialDaysOpeningHours = {
        branchId: userSettings.branch.id,
        employeeId: props.relatedTo === "employee" ? props.employeeId : undefined,
        openingTimesIds: [id],
      };
      runDelete({ data: deleteData });
    }

    openingHoursDispatch({ type: REMOVE_SPECIAL_DAY, value: { idType, id } });
  };

  return (
    <div>
      <table className={"table table-borderless table-responsive table-sm mb-2"}>
        <tbody>
          {(openingHoursState.existingSpecialDays.length !== 0 ||
            openingHoursState.newSpecialDays.length !== 0) && (
            <tr>
              <td />
              <td />
              <td className={"text-center text-uppercase"}>
                <span className={"badge"}>{props.openFromLabel ?? "Otvorené od"}</span>
              </td>
              <td className={"text-center text-uppercase"}>
                <span className={"badge"}>{props.openToLabel ?? "Otvorené do"}</span>
              </td>
              <td />
            </tr>
          )}

          {openingHoursState.existingSpecialDays.map((_, index) => (
            <ExceptionalOpeningHoursSingle
              key={index}
              listId={index.toString()}
              onRemove={() => removeExceptionalOpeningHours("id", _.id)}
              onDateFromChange={(__) => onChange(__, "id", _.id, "validFromDate")}
              onDateToChange={(__) => onChange(__, "id", _.id, "validToDate")}
              onStartTimeChange={(__) => onChange(__, "id", _.id, "startTime")}
              onEndTimeChange={(__) => onChange(__, "id", _.id, "endTime")}
              onPauseStartTimeChange={(__) => onChange(__, "id", _.id, "pauseStartTime")}
              onPauseEndTimeChange={(__) => onChange(__, "id", _.id, "pauseEndTime")}
              {..._}
              openLabel={props.openLabel}
              closedLabel={props.closedLabel}
            />
          ))}

          {openingHoursState.newSpecialDays.map((_, index) => (
            <ExceptionalOpeningHoursSingle
              key={index}
              listId={index.toString()}
              onRemove={() => removeExceptionalOpeningHours("index", index)}
              onDateFromChange={(__) => onChange(__, "index", index, "validFromDate")}
              onDateToChange={(__) => onChange(__, "index", index, "validToDate")}
              onStartTimeChange={(__) => onChange(__, "index", index, "startTime")}
              onEndTimeChange={(__) => onChange(__, "index", index, "endTime")}
              onPauseStartTimeChange={(__) => onChange(__, "index", index, "pauseStartTime")}
              onPauseEndTimeChange={(__) => onChange(__, "index", index, "pauseEndTime")}
              {..._}
              openLabel={props.openLabel}
              closedLabel={props.closedLabel}
            />
          ))}
        </tbody>
      </table>

      <Button variant={"dark"} size={"sm"} onClick={addNewExceptionalOpeningHours}>
        {props.addNewLabel ?? "Pridať výnimku"}
      </Button>
    </div>
  );
};

export default ExceptionalOpeningHours;
